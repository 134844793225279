import React, { useState } from "react";
import { useWallet } from "@txnlab/use-wallet";

const Connect = () => {
  const [address, setAddress] = useState("CONNECT");
  const { activeAddress } = useWallet();

  function logToFlutter(message) {
    window.postMessage({ type: "LOG TO_FLUTTER", message: message }, "*");
  }
  // Log a message
  console.log(activeAddress);
  logToFlutter("This is a message sent to Flutter.", activeAddress);

  return <div className="bg-secondary" style={{ height: "120px" }}></div>;
};

export default Connect;
