import React, { Fragment, useRef, useState, useEffect } from "react";
import {
  Dialog,
  Transition,
  TransitionChild,
  DialogPanel,
} from "@headlessui/react";
import Img6 from "../Assets/Img/image3.svg";
import { useWallet } from "@txnlab/use-wallet";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { TiWarning } from "react-icons/ti";
import { MdError } from "react-icons/md";
import { HiOutlineClipboardDocument } from "react-icons/hi2";
import { ImCancelCircle } from "react-icons/im";
import Loading from "../Assets/loading.gif";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { algosdk, client, app_id, fame } from "../utils";
import { useTimer } from "../Controller/Constants";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Unstake = () => {
  let [isOpen1, setIsOpen1] = useState(false);

  function openModal1() {
    setIsOpen1(true);
  }
  function closeModal1() {
    setIsOpen1(false);
  }

  //Modal for Donation Success
  let [isOpen2, setIsOpen2] = useState(false);

  function openModal2() {
    setIsOpen2(true);
  }
  function closeModal2() {
    setIsOpen2(false);
  }

  let [isOpen8, setIsOpen8] = useState(false);

  function openModal8() {
    setIsOpen8(true);
  }
  function closeModal8() {
    setIsOpen8(false);
  }

  const cancelButtonRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [txnsId, setTxnsId] = useState(false);
  const { algouBalance, stakedAmount } = useTimer();
  const { signTransactions, activeAddress } = useWallet();
  const creatorAccount = activeAddress;
  const [warning, setWarning] = useState();

  const [unStake, setUnStake] = useState("0");

  const warn = () => {
    toast.warn(warning, {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: "dark",
    });
  };

  const error = () => {
    toast.error("Transaction Cancelled!", {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: "dark",
    });
  };

  const localUnstake = async (sender, index, t, amount) => {
    closeModal1(false);
    setLoading(true);

    if (!creatorAccount) {
      setLoading(false);

      toast.warn("Please connect wallet!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
      });
    } else {
      if (Number(stakedAmount) < Number(unStake)) {
        setLoading(false);

        toast.warn("You cannot unstake more than you have staked!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      } else {
        if (Number(algouBalance) < 2) {
          setLoading(false);

          toast.warn(" Not have enough ALGO for transaction fee !", {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: "dark",
          });
        } else {
          try {
            const appArgs = [];
            appArgs.push(
              new Uint8Array(Buffer.from("w")),
              algosdk.encodeUint64(amount)
            );
            let params = await client.getTransactionParams().do();
            params.fee = 1000;
            params.flatFee = true;

            // create unsigned asset payment transaction
            let ptxn = algosdk.makePaymentTxnWithSuggestedParams(
              sender,
              algosdk.getApplicationAddress(index),
              1000000,
              undefined,
              undefined,
              params
            );

            // create unsigned noop transaction
            let nptxn = algosdk.makeApplicationNoOpTxn(
              sender,
              params,
              index,
              appArgs,
              undefined,
              undefined,
              [t]
            );

            let txns = [ptxn, nptxn];
            txns = algosdk.assignGroupID(txns);

            // Sign the transactions together using the wallet
            const encodedTransaction1 = algosdk.encodeUnsignedTransaction(ptxn);
            const encodedTransaction2 =
              algosdk.encodeUnsignedTransaction(nptxn);

            const signedTransactions = await signTransactions([
              encodedTransaction1,
              encodedTransaction2,
            ]);
            let tx = await client.sendRawTransaction(signedTransactions).do();
            //console.log("Noop + Asset Transfer Transaction: " + tx.txId);
            //console.log("Asset ID:", t);

            // Wait for transaction to be confirmed
            const confirmedTxn = await algosdk.waitForConfirmation(
              client,
              tx.txId,
              4
            );
            //console.log("confirmed" + confirmedTxn["confirmed-round"]);

            //Get the completed Transaction
            //console.log(
            //   "Transaction " +
            //     tx.txId +
            //     " confirmed in round " +
            //     confirmedTxn["confirmed-round"]
            // );

            // display results
            let transactionResponse = await client
              .pendingTransactionInformation(tx.txId)
              .do();
            //console.log(
            //   "Opted-in to app-id:",
            //   transactionResponse["txn"]["txn"]["apid"]
            // );
            if (transactionResponse["local-state-delta"] !== undefined) {
              //console.log(
              //     "Local State updated:",
              //     transactionResponse["local-state-delta"]
              //   );
            }
            setTxnsId(tx.txId);
            setLoading(false);
            openModal2(true);
            // setUnStake("");
          } catch (err) {
            //console.log(err);
            if (err.message.includes("Transaction Request Rejected")) {
              setLoading(false);

              toast.error("Transaction Cancelled!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: "dark",
              });
            } else {
              setLoading(false);

              toast.error("Network Error", {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: "dark",
              });
            }
            setUnStake("");
          }
        }
      }
    }
  };

  const onunstake = async () => {
    const num = parseInt(unStake * 10e1);
    await localUnstake(creatorAccount, app_id, fame, num);
  };

  return (
    <>
      {loading && (
        <div className="top-0 left-0 w-full h-full z-50 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity opacity-100">
          <div
            className="relative flex transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
            style={{
              backgroundColor: "white",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "2",
              height: "180px",
              width: "300px",
              justifyContent: "center",
            }}
          >
            <img src={Loading} alt="loading" className="flex" />
          </div>
        </div>
      )}
      <button
        onClick={openModal1}
        className="dao-btn inline-flex item-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-4 py-2 text-base font-semibold leading-7 text-white bg-black ring-1 ring-gray-900/10 hover:ring-gray-900/20"
      >
        <img src={Img6} alt="" className="dao-icon" />
        <span className="ml-3 dao-name">UNSTAKE</span>
      </button>
      <Transition show={isOpen1} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeModal1}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      </div> */}
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Unstake
                        </Dialog.Title>
                        <div className="mt-2">
                          <input
                            className=" bg-gray-200 disabled text-gray-700 border rounded-md py-3 px-4 mb-3 w-full leading-tight focus:outline-none focus:bg-white"
                            id="grid-first-name"
                            type="number"
                            value={unStake}
                            onChange={(e) => setUnStake(e.target.value)}
                            placeholder="16,000"
                          />
                        </div>

                        <Dialog.Description className="text-sm font-bold">
                          Heads up! Unstaking? You'll reset those sweet bonuses.
                        </Dialog.Description>
                      </div>
                      <button
                        type="button"
                        className="inline-flex lg:mt-8 md:mt-8 w-full justify-center rounded-md bg-secondary px-3 py-3 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                        onClick={() => setUnStake(stakedAmount)}
                      >
                        MAX
                      </button>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 grid grid-cols-2 gap-4">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={() => closeModal1(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                      onClick={() => {
                        closeModal1(false);
                        onunstake();
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition show={isOpen2} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeModal2}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="text-center">
                      <BsFillCheckCircleFill className="text-secondary donate-check inline-block" />
                      <p className="mt-3 donate-confirmed">
                        Successfully unstaked {unStake} FAME
                      </p>
                      <div className="flex">
                        <p
                          className="mt-1"
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Transaction Id :{" "}
                          <span
                            onClick={openModal8}
                            className="text-secondary cursor-pointer"
                          >
                            {txnsId}
                          </span>
                        </p>
                        <CopyToClipboard text={txnsId}>
                          <button className="inline-flex ml-3 item-center transition ease-in-out hover:text-secondary text-white hover:bg-white hover:outline delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-2 py-1 text-base font-semibold leading-7 text-white bg-secondary ring-1 ring-gray-900/10 hover:ring-gray-900/2">
                            <p>Copy</p>
                            <HiOutlineClipboardDocument
                              style={{ fontSize: "20px" }}
                              className="ml-2 mt-1  text-center"
                            />
                          </button>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* iframe */}
      <Transition show={isOpen8} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeModal8}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 ">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="text-center ">
                      <iframe
                        src={`https://allo.info/tx/${txnsId}`}
                        width={350}
                        height={500}
                      ></iframe>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Unstake;
