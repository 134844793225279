import React, { useState, useEffect, Fragment, useRef } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  Dialog,
  Transition,
  TransitionChild,
  DialogPanel,
  Menu,
} from "@headlessui/react";
import "./dao.css";
import { FadeIn } from "react-slide-fade-in";
// import Img1 from "../Assets/Img/logo.png";
import Forum from "../Components/Forum";
import Img4 from "../Assets/Img/image1.svg";
import Current from "../Components/Current";
import Submit from "../Components/Submit";
import Stake from "../Components/Stake";
import Unstake from "../Components/Unstake";
import Donate from "../Components/donate";
import { useWallet, walletconnect } from "@txnlab/use-wallet";
import { getCountdownDate, getTimeUsed } from "../../Global/Countdown";
import { useTimers } from "../Controller/Constants";
import Claim from "../Components/Claim";
import NextProposal from "../Controller/NextProposal";
import { Previous } from "../Components/Previous";
import { powerByStakeDuration, powerByVote } from "../../Global/VotePower";
import { fame, app_id } from "../utils";
export default function Dao() {
  //Get User Wallet Address from Local Storage
  const [address, setAddress] = useState("CONNECT");
  const { activeAddress } = useWallet();
  const {
    walletBalance,
    algouBalance,
    stakedAmount,
    pv,
    p,
    uv,
    dv,
    days,
    hours,
    minutes,
    seconds,
    treasuryBalance,
    algoBalance,
    glFameBalance,
    usdcBalance,
    totalStake,
    lastVote,
    endTime,
    coolDown,
    duration,
    proposal_Type,
    proposer,
    birthday,
    numVote,
    userFameBalance,
    userUsdcBalance,
  } = useTimers();
  const account = activeAddress;

  let [isOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (!account) {
      setAddress("CONNECT");
    } else {
      setAddress(account);
    }
  }, [account, setAddress]);

  const cancelButtonRef = useRef(null);

  //GetCoundownDate
  const date = getCountdownDate(days, hours, minutes, seconds);

  const result = uv + dv;
  const upvote = ((uv / result) * 100).toFixed(2);

  //Treasury Fame Balance
  const realFameBal = (treasuryBalance - totalStake).toFixed(2);

  //theshold
  const threshold = ((30 / 100) * totalStake).toFixed(2);

  //Current Time
  const currentTime = Date.now() / 1000;

  //End of cool Time
  const endOfCoolDown = endTime + coolDown;

  //Voting Power
  function truncateDecimals(number, digits) {
    const multiplier = Math.pow(10, digits);
    return Math.floor(number * multiplier) / multiplier;
  }
  // console.log(stakedAmount);
  // console.log(numVote);
  // console.log(birthday);
  // console.log(currentTime);

  const power1 = powerByVote(stakedAmount, numVote);
  const power2 = powerByStakeDuration(
    stakedAmount,
    birthday,
    currentTime,
    power1
  );
  const number = Number(power2);
  const votingPower = truncateDecimals(number, 2);
  // console.log(votingPower);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <div>
        <section className="france-dao-hero pt-20 p-10 lg:p-20 lg:pb-10 md:pb-10 md:p-20">
          <div style={{ maxWidth: "1400px", margin: "0 auto" }}>
            <div className="pt-20">
              <FadeIn
                from="right"
                positionOffset={400}
                triggerOffset={200}
                delayInMilliseconds={0}
              >
                <p className="text-white  lg:pb-0 sm:pb-0 lg:text-right text-left dao-hero-heading">
                  AlgorandFRANCE League
                </p>
              </FadeIn>
            </div>
            {/* <Menu
              as="div"
              className="relative justify-right lg:text-right text-right"
            >
              <div>
                <Menu.Button className="inline-flex  gap-x-1.5 text-white px-3 py-2 text-sm font-semibold  ">
                  GOVERNOR
                  <ChevronDownIcon
                    className="-mr-1 h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-30 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href=""
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm text-center"
                          )}
                        >
                          GOVERNOR
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="https://algofame.org/dao"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm text-center"
                          )}
                        >
                          MAINNET
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="https://testnet.algofame.org/"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm text-center"
                          )}
                        >
                          TESTNET
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu> */}

            <div className="lg:mt-0 mt-5">
              <FadeIn
                from="left"
                positionOffset={400}
                triggerOffset={200}
                delayInMilliseconds={0}
              >
                <div className="dao-card md:mt-8">
                  <div className="lg:p-5 p-5  lg:pl-10 card-content">
                    <div className="grid lg:grid-cols-4 grid-cols-2 gap-4">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="inline-flex item-center grid"
                        href="https://app.tinyman.org/#/swap?asset_in=0&asset_out=142838028"
                      >
                        <button className="dao-btn inline-flex item-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-4 py-2 text-base font-semibold leading-7 text-white bg-blue ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                          <img src={Img4} alt="" className="dao-icon" />
                          <span className="ml-3 dao-name">BUY</span>
                        </button>
                      </a>
                      <Stake />
                      <Unstake />
                      <Donate />
                    </div>
                    <div className="dao-hero-text ml-5">
                      <p className="text-blue font-extrabold">
                        {walletBalance > 0 || stakedAmount > 0
                          ? "MEMBER"
                          : "No Membership"}
                      </p>
                      <p className="text-red">
                        {stakedAmount > 0 ? "Staked" : "No Stake"}
                      </p>
                      <p className="text-blue">
                        FAME balance - {userFameBalance} FAME
                      </p>

                      <p className="text-red">Voting Power - {votingPower}</p>

                      <p className="text-black">
                        {/* ALGO balance - {algouBalance} ALGO */}
                      </p>
                      {/* <p className="text-red">
                        USDC balance - {userUsdcBalance} USDC
                      </p> */}
                      {/* <p>
                        Hover over this text to see a tooltip:
                        <span class="tooltip">
                          Hover me
                          <span class="tooltiptext">This is a tooltip</span>
                        </span>
                      </p> */}
                    </div>
                  </div>
                </div>
              </FadeIn>
            </div>
          </div>
        </section>

        <section className="p-10 lg:p-20 lg:pt-10 md:pt-10 md:p-20">
          <div
            className="lg:grid grid-cols-5 gap-20"
            style={{ maxWidth: "1400px", margin: "0 auto" }}
          >
            {/* Show remaining Time for Next Proposal after Endtime  */}
            {currentTime > endOfCoolDown ? (
              <>
                <Submit />
                <Current type="none" />
              </>
            ) : currentTime > endTime && currentTime < endOfCoolDown ? (
              <NextProposal />
            ) : currentTime < endOfCoolDown ? (
              <>
                <Submit type="none" />
                <Current />
              </>
            ) : (
              <Submit />
            )}

            <div className="col-span-2 lg:pt-0 pt-10">
              <div className="vote-sec">
                <div className="tape treasury text-white p-5 bg-blue">
                  <p>AlgorandFRANCE treasury</p>
                  <img src={Img4} alt="logo" />
                </div>
                <div className="text-black lg:p-5 p-5 lg:pb-5 treasury-bal">
                  <p>
                    Card balance: <span>{realFameBal}</span>
                  </p>
                  <p>
                    Algo balance: <span>{algoBalance} ALGO</span>
                  </p>
                  <p>
                    Fame balance: <span>{glFameBalance} FAME</span>
                  </p>
                  <p>
                    Usdc balance: <span>{usdcBalance} USDC</span>
                  </p>
                  <p>
                    Total voting power: <span>{totalStake}</span>
                  </p>
                  <p>
                    Threshold: <span>{threshold} </span>
                  </p>
                  {/* <p>
                    AN Other ASA: <span>367,200,579</span>
                  </p> */}
                </div>
                <div className="token lg:flex p-5 lg:p-5 lg:pt-0">
                  <p>DApp {app_id} </p>
                  <p className="lg:block hidden"> • </p>
                  <p> NFT CARD $AFCARD {fame}</p>
                </div>
              </div>
              {currentTime > endTime ? <Forum type="none" /> : <Forum />}

              <Previous />
            </div>
          </div>
        </section>
        <section className="p-10 lg:p-20 lg:pt-10 md:pt-10 md:p-20 about-sec">
          <div
            className="lg:grid grid-cols-11 gap-5"
            style={{ maxWidth: "1400px", margin: "0 auto" }}
          >
            <div className="col-span-6">
              <p className="about">Bienvenue à la Ligue DAO!</p>
              <p className="mt-3 about-text">
                Les DAOs de Ligue fonctionnent de manière similaire à la DAO
                Globale, mais avec quelques différences clés dans la
                détermination du pouvoir de vote et des règles spécifiques
                adaptées aux besoins uniques de leur communauté. Voici un résumé
                des règles et des avantages pour les DAOs de Ligue.
              </p>

              {/* <button className="flex mt-5 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-4 py-1.5 text-base font-semibold leading-7 text-white bg-secondary ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                More about the FAME DAO
              </button>
              <button className="flex mt-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-4 py-1.5 text-base font-semibold leading-7 text-white bg-black ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                DAO Constitution and rules
              </button> */}
            </div>
            <p className="how text-blue  mt-5 col-span-9">
              Comment ça fonctionne
            </p>

            <div className="col-span-3 mt-3 sm:mt-0">
              <p className="font-bold text-left">A - Règles de la DAO</p>
              {/* <p className="about-text mt-2">
                Here’s how you can maximize your influence in the FAME DAO:
              </p> */}
              <p className="about-text mt-2">
                <span className="font-bold">
                  1. Exigences préalables à la proposition :
                </span>
                Exigence de staking: Vous devez staker votre carte de membre NFT
                avant de faire des propositions
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">2. Limites de proposition: </span>
              </p>
              <p className="about-text mt-1">
                <span className="font-bold">
                  - Plafond de demande de financement :
                </span>
                Vous pouvez demander jusqu'à 10 % des fonds disponibles en
                $FAME, $ALGO ou $USDC
              </p>
              <p className="about-text mt-1">
                <span className="font-bold">
                  - Justification de la proposition :
                </span>
                Expliquez clairement pourquoi votre proposition devrait recevoir
                des fonds
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">
                  3. Coûts des propositions et des votes :
                </span>
              </p>
              <p className="about-text mt-1">
                <span className="font-bold">- Frais de proposition: </span>Il en
                coûte 2 $ALGO pour soumettre une proposition.
              </p>
              <p className="about-text mt-1">
                <span className="font-bold">- Frais de vote : </span>0.01 $ALGO.
              </p>
              <p className="about-text mt-1">
                <span className="font-bold">
                  - Récompense pour les nouveaux membres :
                </span>
                Tout nouveau membre ajouté gagne 1 $ALGO de la part du proposeur
              </p>
              <p className="about-text mt-1">
                <span className="font-bold">
                  - Récompense pour le proposant :
                </span>
                Tout proposant qui réclame les fonds après un vote réussi
                récupère 1 $ALGO
              </p>
            </div>

            <div className="col-span-3 lg:mt-0 mt-5">
              <p className="font-bold text-left">
                B - Augmentez votre pouvoir de vote
              </p>

              <p className="about-text mt-2">
                <span className="font-bold">1. Carte de membre NFT :</span>
              </p>
              <p className="about-text mt-1">
                <span className="font-bold">- Pouvoir de vote de base : </span>
                Pour obtenir le pouvoir de proposition et de vote, vous devez
                staker votre carte de membre NFT. La détenir simplement dans
                votre portefeuille ne suffit pas
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">
                  2. Augmentation du pouvoir de vote :
                </span>
              </p>
              <p className="about-text mt-1">
                <span className="font-bold">- Croissance mensuelle :</span>
                En stakant votre carte de membre NFT, votre pouvoir de vote
                augmente de 10 % chaque mois. Restez pendant 5 mois et vous
                gagnez 50 % de force de vote supplémentaire
              </p>
              <p className="about-text mt-1">
                <span className="font-bold">- Engagement de vote : </span>
                Chaque fois que vous votez, vous recevez une augmentation de 10
                % du pouvoir de vote, jusqu'à 50 % après avoir voté 5 fois. Par
                exemple, en stakant votre NFT pendant 5 mois et en votant 5
                fois, vous doublez votre pouvoir de vote
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">
                  3. Réinitialisation lors de l'unstaking :
                </span>
              </p>
              <p className="about-text mt-1">
                <span className="font-bold">
                  - Condition de réinitialisation :
                </span>
                Unstaker votre carte de membre NFT réinitialise tous les bonus
                de pouvoir de vote accumulés
              </p>
            </div>

            <div className="col-span-5 lg:mt-0 mt-5">
              <p className="font-bold text-left">
                C - Devenir gouverneur de la Ligue
              </p>
              <p className="about-text mt-2">
                Pour éviter le problème de la ploutocratie souvent observé dans
                les DAOs, où la gouvernance est dominée par ceux qui peuvent se
                permettre d'acheter le plus de jetons, les DAOs de Ligue mettent
                en œuvre une structure de gouvernance unique qui décentralise le
                pouvoir de manière organique:
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">1. Gouverneur initial:</span>
              </p>
              <p className="about-text mt-1">
                - La gouvernance commence avec un gouverneur initial qui propose
                et vote pour ajouter de nouveaux gouverneurs.
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">2. Ajout de gouverneurs:</span>
              </p>
              <p className="about-text mt-1">
                <span className="font-bold">
                  - Proposition de nouveaux gouverneurs:
                </span>
                Le gouverneur initial propose de nouveaux gouverneurs. Chaque
                nouveau gouverneur doit être voté par les gouverneurs actuels.
              </p>

              <p className="about-text mt-1">
                <span className="font-bold">- Processus de vote: </span>
                Les propositions pour ajouter de nouveaux gouverneurs suivent
                les mêmes règles de vote que les autres propositions,
                nécessitant plus de votes favorables que défavorables et la
                participation d'au moins 30 % du pouvoir de vote total.
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">
                  3. Décentralisation du pouvoir:
                </span>
              </p>
              <p className="about-text mt-1">
                - À mesure que de nouveaux gouverneurs sont ajoutés, le pouvoir
                devient plus décentralisé, favorisant une prise de décision
                équilibrée et équitable.
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">
                  4. Dynamique du pouvoir de vote:
                </span>
              </p>
              <p className="about-text mt-1">
                - Avec chaque nouveau gouverneur, le pouvoir de vote est réparti
                plus équitablement, favorisant un engagement actif et empêchant
                toute personne de détenir trop d'influence.
              </p>
            </div>

            <div className="col-span-11 lg:mt-0 mt-5">
              <p className="font-bold text-left">
                D - Chronologie à surveiller
              </p>

              <p className="about-text mt-2">
                <span className="font-bold">1. Période de vote:</span>
              </p>
              <p className="about-text mt-1">
                <span className="font-bold">- Durée:</span>Chaque proposition en
                direct a une période de vote de 7 jours pour que la communauté
                puisse décider.
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">
                  2. Période de réclamation des jetonsd:
                </span>
              </p>
              <p className="about-text mt-1">
                <span className="font-bold">- Fenêtre de réclamation:</span>
                Si votre proposition est approuvée, vous avez 24 heures pour
                réclamer vos jetons.
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
