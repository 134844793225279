import React from "react";

const Duration = ({ percent, bg }) => {
  return (
    <div
      className="bg-grey relative"
      style={{
        height: "100%",
        width: "20px",
        borderRadius: "10px",
        margin: "0 auto",
        overflow: "hidden",
      }}
    >
      <div
        className={`absolute ${bg}`}
        style={{
          height: `${percent}%`,
          width: "20px",
          borderRadius: "0px 0px 10px 10px",
          bottom: "0",
          transition: "width 3s ",
        }}
      >
        <div>
          <p className="bg-white" style={{ height: "1px" }}></p>
        </div>
      </div>
    </div>
  );
};

export default Duration;
