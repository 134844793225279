import React, { createContext, useContext, useState, useEffect } from "react";
import { useWallet } from "@txnlab/use-wallet";
import { appState, refreshBalance, refreshTreasuryBalance } from "./Balance";
import { app_id, algosdk } from "../utils";

const ConstantContext = createContext();

const UpdateInterval = 3000; // Update every 3 seconds

const useInterval = (callback, delay) => {
  useEffect(() => {
    const intervalId = setInterval(callback, delay);
    return () => clearInterval(intervalId);
  }, [callback, delay]);
};

export const ConstantProvider = ({ children }) => {
  const { activeAddress } = useWallet();
  const account = activeAddress;

  const [walletBalance, setwalletBalance] = useState("0");
  const [algouBalance, setuAlgoBalance] = useState("0");
  const [stakedAmount, setStakedAmount] = useState("0");

  const [pv, setPv] = useState("0");
  const [p, setP] = useState("0");
  const [uv, setUv] = useState("0");
  const [dv, setDv] = useState("0");
  const [totalStake, setTotalStake] = useState("0");
  const [lastVote, setLastVote] = useState();
  const [endTime, setEndTime] = useState();
  const [coolDown, setCoolDown] = useState();
  const [duration, setDuration] = useState();
  const [proposal_Type, setProposalType] = useState();
  const [proposer, setProposer] = useState();
  const [birthday, setBirthday] = useState();
  const [numVote, setNumVote] = useState();
  const [threshold, setThreshold] = useState();

  const [days, setDays] = useState();
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();

  const [treasuryBalance, setTreasuryBalance] = useState("0");
  const [algoBalance, setAlgoBalance] = useState("0");

  // Refresh balance
  useInterval(() => {
    refreshBalance(
      account,
      setwalletBalance,
      setuAlgoBalance,
      setStakedAmount,
      setNumVote,
      setBirthday,
      setLastVote
    );
  }, UpdateInterval);

  // Refresh app state
  useInterval(() => {
    appState(
      setPv,
      setP,
      setUv,
      setDv,
      setDays,
      setHours,
      setMinutes,
      setSeconds,
      setTotalStake,
      setEndTime,
      setCoolDown,
      setDuration,
      setProposalType,
      setProposer,
      setThreshold
    );
  }, UpdateInterval);

  // Refresh treasury balance
  const Treasuryaccount = algosdk.getApplicationAddress(app_id);
  useInterval(() => {
    refreshTreasuryBalance(Treasuryaccount, setTreasuryBalance, setAlgoBalance);
  }, UpdateInterval);

  const contextValue = {
    walletBalance,
    algouBalance,
    stakedAmount,
    pv,
    p,
    uv,
    dv,
    days,
    hours,
    minutes,
    seconds,
    treasuryBalance,
    algoBalance,
    totalStake,
    lastVote,
    endTime,
    coolDown,
    duration,
    proposal_Type,
    proposer,
    birthday,
    numVote,
    threshold,
  };

  return (
    <ConstantContext.Provider value={contextValue}>
      {children}
    </ConstantContext.Provider>
  );
};

export const useTimer = () => {
  return useContext(ConstantContext);
};
