import React, { useState, useEffect, Fragment, useRef } from "react";
import {
  Dialog,
  Transition,
  TransitionChild,
  DialogPanel,
  Menu,
} from "@headlessui/react";
import Logo from "../Assets/Img/logo.svg";
import { FaBars, FaPowerOff, FaTimes } from "react-icons/fa";
import { BsTriangleFill } from "react-icons/bs";
import { IconContext } from "react-icons/lib";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { NavLink } from "react-router-dom";
import { useWallet } from "@txnlab/use-wallet";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Connect from "../../Global/Connect";
// import { useEffect } from "react";
import Flag from "react-country-flags";
import NavLinks from "../../Global/NavLink";

export default function DaoNav() {
  const [click, setClick] = useState(false);

  let [isOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const cancelButtonRef = useRef(null);

  const [address, setAddress] = useState("CONNECT");
  const { activeAddress } = useWallet();
  const account = activeAddress;

  useEffect(() => {
    if (!account) {
      setAddress("CONNECT");
    } else {
      setAddress(account);
    }
  }, [account, setAddress]);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const [colorChange, setColorchange] = useState(false);
  const [navLogo, setNavLogoChange] = useState(false);
  const [navMenuUp, setNavMenuUp] = useState(true);
  const [flagWallet, setFlagWallet] = useState(true);

  const changeNavbarColor = () => {
    if (window.scrollY > 0) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };

  const changeLogoSize = () => {
    if (window.scrollY > 0) {
      setNavLogoChange(true);
    } else {
      setNavLogoChange(false);
    }

    if (window.scrollY > 0) {
      setNavMenuUp(false);
    } else {
      setNavMenuUp(true);
    }

    if (window.scrollY > 0) {
      setFlagWallet(false);
    } else {
      setFlagWallet(true);
    }
  };

  window.addEventListener("scroll", changeNavbarColor);
  window.addEventListener("scroll", changeLogoSize);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <IconContext.Provider value={{ color: "#" }}>
        <nav className={colorChange ? "navbar colorEboyin" : "navbar"}>
          <div
            className="navbar-container container lg:pl-20 lg:pr-20"
            style={{ maxWidth: "1400px", margin: "0 auto" }}
          >
            <div className={navLogo ? " navLogo" : "navbar-logo"}>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  "nav-links" + (isActive ? " activated" : "")
                }
                onClick={closeMobileMenu}
              >
                <img src={Logo} alt="Logo" />
              </NavLink>
            </div>
            <div className={navMenuUp ? " navMenuUp" : "menu-icon"}>
              <div className="text-white" onClick={handleClick}>
                {click ? <FaTimes /> : <FaBars />}
              </div>
            </div>
            <div className="flag-wallet   block">
              <div className={flagWallet ? " flagWallet" : "flag-wallet"}>
                <NavLink
                  className={({ isActive }) =>
                    "nav-links" + (isActive ? " activated" : "")
                  }
                  onClick={closeMobileMenu}
                >
                  <button
                    onClick={openModal}
                    className="items-center transition ease-in-out inline-flex rounded-lg connect-btn px-4 py-1.5 text-base font-semibold leading-7 off shadow-sm"
                  >
                    <FaPowerOff className="text-white" />
                    <span className="ml-3 text-white">{address}</span>
                  </button>
                </NavLink>
              </div>
            </div>

            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <div className="triangle lg:hidden md:hidden">
                <BsTriangleFill />
              </div>
              <NavLinks />

              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    "nav-links" + (isActive ? " activated" : "")
                  }
                  onClick={closeMobileMenu}
                >
                  <button
                    onClick={openModal}
                    className="items-center transition ease-in-out inline-flex rounded-lg connect-btn px-4 py-1.5 text-base font-semibold leading-7  shadow-sm hidden md:flex"
                  >
                    <FaPowerOff className="text-white" />
                    <span className="ml-3 text-white">{address}</span>
                  </button>
                  <Transition show={isOpen} as={Fragment}>
                    <Dialog
                      as="div"
                      className="relative z-10"
                      initialFocus={cancelButtonRef}
                      onClose={closeModal}
                    >
                      <TransitionChild
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                      </TransitionChild>

                      <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
                          <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                          >
                            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="w-100 h-100">
                                  {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-brown-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <ExclamationTriangleIcon
                                      className="h-6 w-6 text-brown-600"
                                      aria-hidden="true"
                                    />
                                  </div> */}
                                  <Connect />
                                </div>
                              </div>
                            </DialogPanel>
                          </TransitionChild>
                        </div>
                      </div>
                    </Dialog>
                  </Transition>
                </NavLink>
              </li>

              <li id="google_translate_element"></li>
            </ul>
          </div>
        </nav>
      </IconContext.Provider>
    </>
  );
}
