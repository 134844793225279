import React, { createContext, useContext, useState, useEffect } from "react";
import {
  appState,
  refreshBalance,
  refreshTreasuryBalance,
} from "../Components/Balance";
import { useWallet } from "@txnlab/use-wallet";
import { app_id, algosdk } from "../utils";

const ConstantContexts = createContext();

const UpdateInterval = 60000; // Update every 60 seconds

export const LocalConstantProviders = ({ children }) => {
  const { signTransactions, activeAddress } = useWallet();
  const account = activeAddress;
  //user balance
  const [walletBalance, setwalletBalance] = useState("0");
  const [algouBalance, setuAlgoBalance] = useState("0");
  const [stakedAmount, setStakedAmount] = useState("0");
  const [userFameBalance, setUserFameBalance] = useState("0");
  const [userUsdcBalance, setUserUsdcBalance] = useState("0");

  //Dao State variables
  const [pv, setPv] = useState("0");
  const [p, setP] = useState("0");
  const [uv, setUv] = useState("0");
  const [dv, setDv] = useState("0");
  const [totalStake, setTotalStake] = useState("0");
  const [lastVote, setLastVote] = useState();
  const [endTime, setEndTime] = useState();
  const [coolDown, setCoolDown] = useState();
  const [duration, setDuration] = useState();
  const [proposal_Type, setProposalType] = useState();
  const [proposer, setProposer] = useState();
  const [birthday, setBirthday] = useState();
  const [numVote, setNumVote] = useState();
  const [threshold, setThreshold] = useState();
  const [receiver, setReceiver] = useState();
  //Dao Time
  const [days, setDays] = useState();
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();
  //Treasury Balance
  const [treasuryBalance, setTreasuryBalance] = useState("0");
  const [algoBalance, setAlgoBalance] = useState("0");
  const [stakeAmounts, setStakedAmounts] = useState("0");
  const [usdcBalance, setUsdcBalance] = useState("0");
  const [glFameBalance, setGlFameBalance] = useState("0");
  const [proposedToken, setProposedToken] = useState("0");

  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshBalance(
        account,
        setwalletBalance,
        setUserFameBalance,
        setUserUsdcBalance,
        setuAlgoBalance,
        setStakedAmount,
        setNumVote,
        setBirthday,
        setLastVote
      );
    }, 3000);

    return () => clearInterval(intervalId);
  }, [
    account,
    setwalletBalance,
    setUserFameBalance,
    setUserUsdcBalance,
    setuAlgoBalance,
    setStakedAmount,
    setNumVote,
    setBirthday,
    setLastVote,
  ]);
  //console.log(numVote);
  useEffect(() => {
    const intervalId = setInterval(() => {
      appState(
        setPv,
        setP,
        setUv,
        setDv,
        setDays,
        setHours,
        setMinutes,
        setSeconds,
        setTotalStake,
        setEndTime,
        setCoolDown,
        setDuration,
        setProposalType,
        setProposer,
        setReceiver,
        setThreshold,
        setProposedToken
      );
    }, 3000);

    return () => clearInterval(intervalId);
  }, [
    setPv,
    setP,
    setUv,
    setDv,
    setDays,
    setHours,
    setMinutes,
    setSeconds,
    setTotalStake,
    setEndTime,
    setCoolDown,
    setDuration,
    setProposalType,
    setProposer,
    setReceiver,
    setThreshold,
    setProposedToken,
  ]);

  const Treasuryaccount = algosdk.getApplicationAddress(app_id);
  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshTreasuryBalance(
        Treasuryaccount,
        setTreasuryBalance,
        setAlgoBalance,
        setGlFameBalance,
        setUsdcBalance
      );
    }, 3000);

    return () => clearInterval(intervalId);
  }, [
    Treasuryaccount,
    setTreasuryBalance,
    setAlgoBalance,
    setGlFameBalance,
    setUsdcBalance,
  ]);

  const contextValue = {
    walletBalance,
    algouBalance,
    stakedAmount,
    pv,
    p,
    uv,
    dv,
    days,
    hours,
    minutes,
    seconds,
    treasuryBalance,
    algoBalance,
    glFameBalance,
    usdcBalance,
    totalStake,
    lastVote,
    endTime,
    coolDown,
    duration,
    proposal_Type,
    proposer,
    birthday,
    numVote,
    receiver,
    threshold,
    userFameBalance,
    userUsdcBalance,
    proposedToken,
  };

  return (
    <ConstantContexts.Provider value={contextValue}>
      {children}
    </ConstantContexts.Provider>
  );
};

export const useTimers = () => {
  return useContext(ConstantContexts);
};
