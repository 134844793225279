import md5 from "md5";
import React, { useEffect, useState, Fragment, useRef } from "react";
import Progress from "../Components/Progress";
import { getCountdownDate, proposalDate } from "../../Global/Countdown";
import { getAllProposals } from "../Lib/Propose";
// import { getAllProposals } from "../Lib/Description";
import {
  Dialog,
  Transition,
  TransitionChild,
  DialogPanel,
} from "@headlessui/react";
import { getAllDescriptions, getDescription } from "../Lib/Description";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.core.css"; // Import the styles
import getForumData from "../Controller/Comments";
import { useTimers } from "../Controller/Constants";
import { getForum } from "../Lib/Forum";
import { proposalTime } from "../../Global/Countdown";
import { toast } from "react-toastify";
import axios from "axios";

export const Previous = () => {
  const [proposals, setProposals] = useState([]);
  const [showAllProposals, setShowAllProposals] = useState(false);
  const [txnsId, setTxnsID] = useState();
  const [selectedProposal, setSelectedProposal] = useState(null);
  const { proposer } = useTimers();
  const [results, setResults] = useState([]);
  const { endTime } = useTimers();

  const getProposals = async () => {
    const showToast = (message) => {
      toast.error(message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
      });
    };
    const data = await getAllProposals();

    const proposalsData = data.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    // console.log(proposalsData);

    // Fetch descriptions for all proposals concurrently
    const descriptionPromises = proposalsData.map(async (proposal, i) => {
      // Use proposal.description to fetch the long description
      const longDescription = await getDescription(proposal.description);
      // console.log(longDescription);
      const proposer = longDescription;

      return { ...proposal, long_desc: longDescription };
    });
    // console.log("====================================");
    // console.log(descriptionPromises);
    // console.log("====================================");

    // Wait for all description promises to resolve
    const proposalDesc = await Promise.all(descriptionPromises);

    // Calculate upvote for each proposal
    const updatedProposals = await Promise.all(
      proposalDesc.map(async (proposal) => {
        const getForumData = async () => {
          const endTime = proposal.endTime;
          // console.log(endTime);
          const wallet = proposal["long_desc"][0]?.wallet;
          // console.log(wallet);
          try {
            const proposerCode = wallet?.substring(0, 7) || "";
            const proposalCode = proposerCode + endTime;
            // console.log(proposalCode);
            const data = await getForum(proposalCode);

            if (data.length === 0) {
              console.log("Data is empty");
              return [];
            }

            const updatedForum = await Promise.all(
              data.map(async (forum) => {
                const userHash = md5(
                  forum.user_id.substring(0, 4) + forum.user_id.slice(-6)
                ).substring(0, 7);

                let userName = userHash;

                try {
                  const response = await axios.get(
                    `https://api.nf.domains/nfd/lookup?address=${forum.user_id}`
                  );

                  if (response.status === 200) {
                    userName = response.data[forum.user_id]?.name || userHash;
                  }
                } catch (error) {
                  // console.error("Error fetching user data:", error);
                }

                return {
                  ...forum,
                  time: proposalTime(forum.date),
                  user: userName,
                };
              })
            );

            return updatedForum;
          } catch (error) {
            // console.error("Error in getForumData:", error);
            // showToast("Error retrieving forum data");
            return [];
          }
        };

        const forumData = await getForumData();

        const upvote = parseInt(proposal.up);
        const dnvote = parseInt(proposal.down);
        const result = upvote + dnvote;

        const upvotePercent = result ? ((upvote / result) * 100).toFixed(2) : 0;
        const dnvotePercent = result ? ((dnvote / result) * 100).toFixed(2) : 0;
        const date = proposalDate(proposal.endTime);

        return {
          ...proposal,
          upvote,
          date,
          dnvote,
          upvotePercent,
          dnvotePercent,
          forumData,
        };
      })
    );

    // Now you can use the updatedProposals array with forumData added to each proposal
    // console.log(updatedProposals);

    // console.log("====================================");
    // console.log(updatedProposals);
    // console.log("====================================");

    setProposals(updatedProposals);
  };

  // Function to toggle the visibility of all proposals
  const toggleAllProposals = () => {
    setShowAllProposals(!showAllProposals);
  };
  // Map only the required number of proposals based on showAllProposals state
  const displayedProposals = showAllProposals
    ? proposals
    : proposals.slice(0, 3);

  const [isOpenAdditionalProposals, setIsOpenAdditionalProposals] =
    useState(false);

  function openAdditionalProposalsModal() {
    setIsOpenAdditionalProposals(true);
  }

  function closeAdditionalProposalsModal() {
    setIsOpenAdditionalProposals(false);
  }

  const [isOpenMore, setIsOpenMore] = useState(false);

  function openMore() {
    setIsOpenMore(true);
  }

  function closeMore() {
    setIsOpenMore(false);
  }

  useEffect(() => {
    getProposals();
  }, []);

  let [isOpen6, setIsOpen6] = useState(false);

  function openModal6() {
    setIsOpen6(false);
    setIsOpen6(true);
  }
  function closeModal6() {
    setIsOpen6(false);
  }

  const cancelButtonRef = useRef(null);

  const modules = {
    toolbar: false, // Show or hide the toolbar based on isEditMode
  };

  const quillStyles = {
    border: "none",
    boxShadow: "none",
    outline: "none",
  };
  const scrollContainerRef = useRef();
  return (
    <div className="mt-10">
      <p className="previous">Previous proposal</p>

      {displayedProposals.map((proposal, index) => (
        <div key={proposal.id} className="mt-5 vote-sec grid grid-cols-10">
          <div className="col-span-1 p-5 pt-5">
            <Progress
              percentup={proposal.upvotePercent}
              percentdn={proposal.dnvotePercent}
              bg="bg-secondary"
            />
          </div>
          <div className="col-span-9 p-5">
            <p className="vote-card-head">
              Ended {proposal.date}
              {/* <p>{proposal.long_desc.long_desc}</p> */}
              {/* {console.log(proposal["long_desc"][0]?.long_desc.toString())} */}
              {proposal.status === "Successful" ? (
                <span className="text-secondary"> {proposal.status}</span>
              ) : (
                <span className="text-primary"> {proposal.status}</span>
              )}
            </p>{" "}
            {proposal.proposalType === "re" ? (
              <p className="vote-card-head mt-2">Referendum</p>
            ) : (
              <p className="vote-card-head mt-2">
                Proposal -{" "}
                {proposal.proposalType === "pa"
                  ? (Number(proposal.receiverAmount) * 10e-5).toFixed(2) +
                    " Algo"
                  : proposal.proposalType === "n"
                  ? (Number(proposal.receiverAmount) * 10e-1).toFixed(2) +
                    " FAME"
                  : 0}
              </p>
            )}
            <p
              className="vote-card-sub mt-5"
              style={{ wordWrap: "break-word" }}
            >
              {proposal.description}
            </p>
            {proposal.status === "Successful" && proposal.txns !== "" ? (
              <p
                className="text-secondary text-left font-bold cursor-pointer mt-5"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                onClick={() => {
                  openModal6();
                  setTxnsID(proposal.txns);
                }}
              >
                ID: {proposal.txns}
              </p>
            ) : proposal.txns !== "" ? (
              <p
                className="mt-5 text-left text-secondary cursor-pointer"
                style={{
                  fontSize: "12px",
                  color: "black",
                }}
              >
                {proposal.txns}
              </p>
            ) : (
              <></>
            )}
            <p
              onClick={() => {
                openMore();
                setSelectedProposal(proposal);
              }}
              className="mt-5 text-secondary more-proposal cursor-pointer"
            >
              More…
            </p>
          </div>
        </div>
      ))}

      {/* <div className="mt-5 vote-sec grid grid-cols-10">
        <div className="col-span-1"></div>
        <div className="col-span-9 p-5">
          <p className="vote-card-head">
            Ended 21 Feb 2023
            <span className="text-secondary"> Successful</span>
          </p>
          <p className="vote-card-head">NFT donation to nigeriafarmproj.algo</p>
          <p className="vote-card-sub mt-5">
            Sending funds for Ijede Farm purchase. Near the Lagos lagoon, the
            new Ijede Farm is intended to provide work for 20 people as well as
            pledging to commit 20% of operating profits back into the FA…
          </p>
        </div>
      </div> */}
      <p
        onClick={openAdditionalProposalsModal}
        className="mt-5 text-secondary more-proposal cursor-pointer"
      >
        More previous FAME proposals…
      </p>

      {/* iframe */}
      <Transition show={isOpen6} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeModal6}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 ">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="text-center ">
                      <iframe
                        src={`https://allo.info/tx/${txnsId}`}
                        width={350}
                        height={500}
                      ></iframe>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition show={isOpenAdditionalProposals} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeAdditionalProposalsModal}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative top-10 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 ">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="text-center ">
                      {proposals.map((proposal, index) => (
                        <div
                          key={proposal.id}
                          className="mt-5 vote-sec grid grid-cols-10"
                        >
                          <div className="col-span-1 p-5 pt-5">
                            <Progress
                              percentup={proposal.upvotePercent}
                              percentdn={proposal.dnvotePercent}
                              bg="bg-secondary"
                            />
                          </div>
                          <div className="col-span-9 p-5">
                            <p className="vote-card-head">
                              Ended {proposal.date}{" "}
                              <span className="text-primary">
                                {proposal.status}
                              </span>
                            </p>
                            {proposal.proposalType === "re" ? (
                              <p className="vote-card-head mt-2">Referendum</p>
                            ) : (
                              <p className="vote-card-head mt-2">
                                Proposal -{" "}
                                {proposal.proposalType === "pa"
                                  ? (
                                      Number(proposal.receiverAmount) * 10e-5
                                    ).toFixed(2) + " Algo"
                                  : proposal.proposalType === "n"
                                  ? (
                                      Number(proposal.receiverAmount) * 10e-1
                                    ).toFixed(2) + " FAME"
                                  : 0}
                              </p>
                            )}
                            <p
                              className="vote-card-sub mt-5"
                              style={{ wordWrap: "break-word" }}
                            >
                              {proposal.description}
                            </p>

                            {proposal.status === "Successful" &&
                            proposal.txns !== "" ? (
                              <p
                                className="text-secondary text-left font-bold cursor-pointer mt-5"
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                onClick={() => {
                                  openModal6();
                                  setTxnsID(proposal.txns);
                                }}
                              >
                                ID: {proposal.txns}
                              </p>
                            ) : proposal.txns !== "" ? (
                              <p
                                className="mt-5 text-left text-secondary cursor-pointer"
                                style={{
                                  fontSize: "12px",
                                  color: "black",
                                }}
                              >
                                {proposal.txns}
                              </p>
                            ) : (
                              <></>
                            )}

                            <p
                              onClick={() => {
                                openMore();
                                setSelectedProposal(proposal);
                              }}
                              className="mt-5 text-secondary more-proposal cursor-pointer"
                            >
                              More…
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition show={isOpenMore} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeMore}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel
                  className="relative top-10 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 "
                  style={{ maxWidth: "600px" }}
                >
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="text-center ">
                      {selectedProposal && (
                        <div className=" grid grid-cols-10">
                          <div className="col-span-1 pt-5">
                            <Progress
                              percentup={selectedProposal.upvotePercent}
                              percentdn={selectedProposal.dnvotePercent}
                              bg="bg-secondary"
                            />
                          </div>
                          <div className="col-span-9 p-5">
                            <p className="vote-card-head">
                              Ended {selectedProposal.date}
                              <span className="text-primary ml-5">
                                {selectedProposal.status}
                              </span>
                            </p>
                            {selectedProposal.proposalType === "re" ? (
                              <p className="vote-card-head mt-2">Referendum</p>
                            ) : (
                              <p className="vote-card-head mt-2">
                                Proposal -{" "}
                                {selectedProposal.proposalType === "pa"
                                  ? (
                                      Number(selectedProposal.receiverAmount) *
                                      10e-5
                                    ).toFixed(2) + " Algo"
                                  : selectedProposal.proposalType === "n"
                                  ? (
                                      Number(selectedProposal.receiverAmount) *
                                      10e-1
                                    ).toFixed(2) + " FAME"
                                  : 0}
                              </p>
                            )}
                            <p
                              className="vote-card-sub mt-5 text-bold"
                              style={{ wordWrap: "break-word" }}
                            >
                              DESCRIPTION -
                              <span> {selectedProposal.description}</span>
                            </p>
                            <div className="text-left">
                              <p className="mt-5 font-bold">VOTES</p>
                              <div className="inline-flex">
                                <div className="bg-secondary h-3 w-3 mt-1.5"></div>
                                <p className="ml-2">
                                  {selectedProposal.up.toFixed(2)}
                                </p>
                              </div>
                              <br />
                              <div className="inline-flex">
                                <div className="bg-black h-3 w-3 mt-1.5"></div>
                                <p className="ml-2">
                                  {selectedProposal.down.toFixed(2)}
                                </p>
                              </div>
                            </div>
                            {selectedProposal["long_desc"][0]?.long_desc && (
                              <>
                                <p className="vote-card-head mt-5 mb-3">
                                  Proposal Pitch
                                </p>
                                <div style={quillStyles}>
                                  <ReactQuill
                                    value={
                                      selectedProposal["long_desc"][0]
                                        ?.long_desc
                                    }
                                    readOnly={true}
                                    modules={modules}
                                  />
                                </div>
                              </>
                            )}
                            <p>
                              {
                                selectedProposal["long_desc"][0]?.long_desc
                                  .toString
                              }
                            </p>

                            {selectedProposal.status === "Successful" &&
                            selectedProposal.txns !== "" ? (
                              <p
                                className="text-secondary text-left font-bold cursor-pointer mt-5"
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                onClick={() => {
                                  openModal6();
                                  setTxnsID(selectedProposal.txns);
                                }}
                              >
                                ID: {selectedProposal.txns}
                              </p>
                            ) : selectedProposal.txns !== "" ? (
                              <p
                                className="mt-5 text-left text-secondary cursor-pointer"
                                style={{
                                  fontSize: "12px",
                                  color: "black",
                                }}
                              >
                                {selectedProposal.txns}
                              </p>
                            ) : (
                              <></>
                            )}

                            {selectedProposal.forumData.length > 0 && (
                              <>
                                <p className="vote-card-head mt-5 mb-3">
                                  Proposal Forum
                                </p>
                                <div
                                  ref={scrollContainerRef}
                                  className="overflow-y-scroll forum-sec p-5 pb-2 text-left"
                                  style={{ maxHeight: "300px" }} // Set a max height if needed
                                >
                                  <div className=" flex flex-col-reverse">
                                    {selectedProposal.forumData.map(
                                      (forum, index) => (
                                        <div
                                          key={index}
                                          className="forum-inner p-2"
                                        >
                                          <p className="text-xs font-bold  pt-1">
                                            @{forum.user}{" "}
                                            <span className="ml-1 mr-1 font-normal">
                                              ||
                                            </span>
                                            {forum.time}
                                            {proposer === forum.user_id && (
                                              <span className="bg-secondary ml-2 rounded-md proposer uppercase">
                                                Proposer
                                              </span>
                                            )}
                                          </p>
                                          <p className="text-sm mt-1">
                                            {forum.message}
                                          </p>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
