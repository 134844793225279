import React, { Fragment, useRef, useState } from "react";
import {
  Dialog,
  Transition,
  TransitionChild,
  DialogPanel,
} from "@headlessui/react";
import Img7 from "../Assets/Img/image4.svg";
import { useWallet } from "@txnlab/use-wallet";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { TiWarning } from "react-icons/ti";
import { MdError } from "react-icons/md";
import { HiOutlineClipboardDocument } from "react-icons/hi2";
import { ImCancelCircle } from "react-icons/im";
import Loading from "../Assets/loading.gif";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useWindowSize } from "react-use";
import { algosdk, client, fame_global, usdc, app_id, fame } from "../utils";
import { useTimers } from "../Controller/Constants";
import Confetti from "react-confetti";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Donate = () => {
  // Modal for Donate (Add Amount)
  let [isOpen1, setIsOpen1] = useState(false);

  function openModal1() {
    setIsOpen1(true);
  }
  function closeModal1() {
    setIsOpen1(false);
  }

  //Modal for Donation Success
  let [isOpen2, setIsOpen2] = useState(false);

  function openModal2() {
    setIsOpen2(true);
  }
  function closeModal2() {
    setIsExploding(false);
    setIsOpen2(false);
  }

  //iFrame
  let [isOpen8, setIsOpen8] = useState(false);

  function openModal8() {
    setIsOpen8(true);
  }
  function closeModal8() {
    setIsOpen8(false);
  }

  const cancelButtonRef = useRef(null);
  const [donate, setDonate] = useState();
  const [loading, setLoading] = useState(false);
  const [txnsId, setTxnsId] = useState(false);
  const { width, height } = useWindowSize();
  const [isExploding, setIsExploding] = useState(false);
  const height2 = height * 3;

  const receiverAccount = algosdk.getApplicationAddress(app_id);
  const { walletBalance, algouBalance, userFameBalance, userUsdcBalance } =
    useTimers();
  const [activeButton, setActiveButton] = useState("FAME");

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
  };

  const { signTransactions, activeAddress } = useWallet();

  let balance;
  if (activeButton === "FAME") {
    balance = userFameBalance;
  } else if (activeButton === "ALGO") {
    balance = algouBalance;
  } else if (activeButton === "USDC") {
    balance = userUsdcBalance;
  }

  const sendTransaction = async (from, to, amount) => {
    closeModal1(false);
    setLoading(true);

    if (!activeAddress) {
      setLoading(false);
      toast.warn("Please Connect Wallet", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
      });
    }
    try {
      if (!from || !to || !amount) {
        throw new Error("Missing transaction params.");
      }

      const params = await client.getTransactionParams().do();
      let transaction;
      if (activeButton === "FAME") {
        transaction = algosdk.makeAssetTransferTxnWithSuggestedParams(
          from,
          to,
          undefined,
          undefined,
          amount,
          undefined,
          fame_global,
          params
        );
      } else if (activeButton === "ALGO") {
        const algo_amount = parseInt(amount * 10e3);
        console.log(from, to);
        transaction = algosdk.makePaymentTxnWithSuggestedParams(
          from,
          to,
          algo_amount,
          undefined,
          undefined,
          params
        );
      } else if (activeButton === "USDC") {
        const amt = amount * 10e3;
        transaction = algosdk.makeAssetTransferTxnWithSuggestedParams(
          from,
          to,
          undefined,
          undefined,
          amt,
          undefined,
          usdc,
          params
        );
      }
      const encodedTransaction = algosdk.encodeUnsignedTransaction(transaction);

      const signedTransactions = await signTransactions([encodedTransaction]);
      let tx = await client.sendRawTransaction(signedTransactions).do();
      setTxnsId(tx.txId);
      setLoading(false);
      openModal2(true);
      setIsExploding(true);
      setDonate("");
    } catch (error) {
      console.log(error);
      if (
        error.message.includes("underflow on subtracting") ||
        error.message.includes("overspend")
      ) {
        setLoading(false);
        // openModal3(true);
        toast.warn("Insufficient Balance", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      } else if (error.message.includes("Request Rejected")) {
        setLoading(false);
        toast.error("Transaction Canclled", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      } else {
        // console.log(error.message);
        setLoading(false);
        toast.error("Network Error! Please try again.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      }
    }
    setDonate(" ");
  };

  const confettiColors = [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4CAF50",
    "#8BC34A",
    "#CDDC39",
    "#FFEB3B",
    "#FFC107",
    "#FF9800",
    "#FF5722",
    "#795548",
  ];

  // //console.log(donate);

  const num = parseInt(donate * 10e1);
  return (
    <>
      {loading && (
        <div className="top-0 left-0 w-full h-full z-50 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity opacity-100">
          <div
            className="relative flex transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
            style={{
              backgroundColor: "white",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "2",
              height: "180px",
              width: "300px",
              justifyContent: "center",
            }}
          >
            <img src={Loading} alt="loading" className="flex" />
          </div>
        </div>
      )}
      <button
        onClick={openModal1}
        className="dao-btn inline-flex item-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-4 py-2 text-base font-semibold leading-7 text-white bg-green ring-1 ring-gray-900/10 hover:ring-gray-900/20"
      >
        <img src={Img7} alt="" className="dao-icon" />
        <span className="ml-3 dao-name">DONATE</span>
      </button>
      {isExploding && (
        <div style={{ maxHeight: "100%" }}>
          {/* <Confetti
            width={width}
            height={height2}
            colors={confettiColors}
            numberOfPieces={2000}
            gravity={0.2}
            style={{ zIndex: "10000" }}
            recycle={true}
          /> */}
        </div>
      )}
      <Transition show={isOpen1} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeModal1}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="grid grid-cols-3 gap-3 flex pb-10">
                      <button
                        className={`${
                          activeButton === "FAME"
                            ? " bg-green text-white"
                            : "bg-white text-black border-solid border-grey border-2"
                        } w-full justify-center rounded-md px-3 py-4 text-sm font-semibold shadow-lg sm:ml-3 sm:w-auto donate-switch`}
                        onClick={() => handleButtonClick("FAME")}
                      >
                        Donate FAME
                      </button>
                      <button
                        className={`${
                          activeButton === "ALGO"
                            ? " bg-green text-white"
                            : "bg-white text-black border-solid border-grey border-2 "
                        }  w-full justify-center rounded-md px-3 py-4 text-sm font-semibold shadow-lg sm:ml-3 sm:w-auto donate-switch`}
                        onClick={() => handleButtonClick("ALGO")}
                      >
                        Donate ALGO
                      </button>
                      <button
                        className={`${
                          activeButton === "USDC"
                            ? " bg-green text-white"
                            : "bg-white text-black border-solid border-grey border-2 "
                        }  w-full justify-center rounded-md px-3 py-4 text-sm font-semibold shadow-lg sm:ml-3 sm:w-auto donate-switch`}
                        onClick={() => handleButtonClick("USDC")}
                      >
                        Donate USDC
                      </button>
                    </div>
                    <p className="pl-4">{activeButton}</p>
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <div className="mt-2">
                          <input
                            className=" bg-gray-200 disabled text-gray-700 border rounded-md py-3 px-4 mb-3 w-full leading-tight focus:outline-none focus:bg-white"
                            id="grid-first-name"
                            type="number"
                            placeholder={activeButton}
                            value={donate}
                            onChange={(e) => setDonate(e.target.value)}
                          />
                        </div>
                      </div>
                      <button
                        type="button"
                        className="inline-flex lg:mt-2 md:mt-3 mt-2 w-full justify-center rounded-md bg-green px-3 py-3 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                        onClick={() => setDonate(Number(balance))}
                      >
                        MAX
                      </button>
                    </div>
                  </div>
                  <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 grid grid-cols-2 gap-4">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-brown px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brown-500 sm:ml-3 sm:w-auto"
                      onClick={() => closeModal1(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-green px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                      onClick={() =>
                        sendTransaction(activeAddress, receiverAccount, num)
                      }
                    >
                      Submit
                    </button>
                  </div>
                  <div className="text-left  px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    {donate !== "" && !/^\d+(\.\d{1,2})?$/.test(donate) && (
                      <p className="text-brown">
                        Max. of two digit after point
                      </p>
                    )}
                    {(donate === "" || donate <= 0) && (
                      <p className="text-brown">
                        Please enter an amount greater that 0
                      </p>
                    )}
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition show={isOpen2} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeModal2}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="text-center">
                      <BsFillCheckCircleFill className="text-secondary donate-check inline-block" />
                      <p className="mt-3 donate-confirmed">
                        Successfully Donated {donate} {activeButton}
                      </p>
                      <p className="donate-confirmed mt-1 mb-1">Thank You!</p>
                      <div className="flex">
                        <p
                          className="mt-1"
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Transaction Id :{" "}
                          <span
                            onClick={openModal8}
                            className="text-secondary cursor-pointer"
                          >
                            {txnsId}
                          </span>
                        </p>
                        <CopyToClipboard text={txnsId}>
                          <button className="inline-flex ml-3 item-center transition ease-in-out hover:text-secondary text-white hover:bg-white hover:outline delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-2 py-1 text-base font-semibold leading-7 text-white bg-secondary ring-1 ring-gray-900/10 hover:ring-gray-900/2">
                            <p>Copy</p>
                            <HiOutlineClipboardDocument
                              style={{ fontSize: "20px" }}
                              className="ml-2 mt-1  text-center"
                            />
                          </button>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* iframe */}
      <Transition show={isOpen8} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeModal8}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 ">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="text-center ">
                      <iframe
                        src={`https://allo.info/tx/${txnsId}`}
                        width={350}
                        height={500}
                      ></iframe>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Donate;
