import { db } from "../firebase-config";
/////
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  orderBy,
  where,
} from "firebase/firestore";

const ErrorColRef = collection(db, "error");

// console.log(forumColRef);
export function addError(newError) {
  return addDoc(ErrorColRef, newError);
}

// export function updateError(id, updatedError) {
//   const forumDoc = doc(db, "error", id);
//   return updateDoc(forumDoc, updatedError);
// }

// export function deleteError(id) {
//   const forumDoc = doc(db, "error", id);
//   return deleteDoc(forumDoc);
// }

// export async function getAllErrors() {
//   const q = query(forumColRef, orderBy("date", "desc"));
//   const querySnapshot = await getDocs(q);
//   const documents = [];

//   querySnapshot.forEach((doc) => {
//     documents.push(doc.data());
//   });
//   // console.log(documents);
//   return documents;
// }

// export async function getError(proposal_id) {
//   const q = query(
//     forumColRef,
//     where("proposal_id", "==", proposal_id),
//     orderBy("date", "desc")
//   );
//   const querySnapshot = await getDocs(q);
//   const documents = [];
//   querySnapshot.forEach((doc) => {
//     documents.push(doc.data());
//   });

//   return documents;
// }
