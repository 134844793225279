function powerByVote(stakedAmount, numVote) {
  if (numVote === 0) {
    const percent = Number(stakedAmount) / 10;
    const powerByVote = Number(stakedAmount);
    return powerByVote;
  } else if (numVote > 5) {
    const percent = Number(stakedAmount) / 2;
    const powerByVote = Number(stakedAmount) + Number(percent);
    return powerByVote;
  } else {
    const percent = Number(stakedAmount) * (Number(numVote) / 10);
    const powerByVote = Number(stakedAmount) + Number(percent);
    return powerByVote;
  }
}

function powerByStakeDuration(
  stakedAmount,
  birthday,
  currentTime,
  powerByVote
) {
  const oneMonth = 2629800;

  if (Number(currentTime) < Number(birthday) + oneMonth || birthday === 0) {
    const percent = Number(stakedAmount) / 10;
    const powerByStakeDuration = Number(powerByVote);
    return powerByStakeDuration;
  } else if (Number(currentTime) >= Number(birthday) + oneMonth * 5) {
    const percent = Number(stakedAmount) / 2;
    const powerByStakeDuration = Number(powerByVote) + Number(percent);

    return powerByStakeDuration;
  } else {
    const percent = (Number(currentTime) - Number(birthday)) / (oneMonth * 10);
    const powerByStakeDuration =
      Number(powerByVote) + Number(stakedAmount) * Number(percent);
    return powerByStakeDuration;
  }
}

export { powerByVote, powerByStakeDuration };
