import React, { useEffect, useState, Fragment, useRef } from "react";
import { useWallet } from "@txnlab/use-wallet";
import { algosdk, client, app_id, fame, usdc, fame_global } from "../utils";
import Loading from "../Assets/loading.gif";
import { useTimers } from "../Controller/Constants";
import {
  Dialog,
  Transition,
  TransitionChild,
  DialogPanel,
} from "@headlessui/react";
import { TiWarning } from "react-icons/ti";
import { ImCancelCircle } from "react-icons/im";
import { HiOutlineClipboardDocument } from "react-icons/hi2";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { BiSad } from "react-icons/bi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useWindowSize } from "react-use";
import Confetti from "react-confetti";
import { getCountdownDate } from "../../Global/Countdown";
import { addProposal } from "../Lib/Propose";
import { proposalPassedThreshold, proposalWonVotes } from "../../Global/Passed";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Claim({ type }) {
  const { activeAddress, signTransactions } = useWallet();
  const [txnsId, setTxnsId] = useState(false);
  const [warning, setWarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [process, setProcess] = useState();
  const { width, height } = useWindowSize();

  const height2 = height * 3;
  const [isExploding, setIsExploding] = useState(false);
  const [pass1, setPass1] = useState();
  const [pass2, setPass2] = useState();

  const account = activeAddress;

  const {
    endTime,
    duration,
    proposer,
    days,
    hours,
    minutes,
    seconds,
    uv,
    dv,
    pv,
    p,
    proposal_Type,
    totalStake,
    threshold,
    proposedToken,
  } = useTimers();

  //Modal for Donation Success
  let [isOpen2, setIsOpen2] = useState(false);

  function openModal2() {
    setIsOpen2(true);
  }
  function closeModal2() {
    setIsOpen2(false);
    setIsExploding(false);
  }

  //Modal for Connect Wallet Warning
  let [isOpen4, setIsOpen4] = useState(false);

  function openModal4() {
    setIsOpen4(true);
  }
  function closeModal4() {
    setIsOpen4(false);
  }

  //Modal for Transaction Cancelled
  let [isOpen5, setIsOpen5] = useState(false);

  function openModal5() {
    setIsOpen5(true);
  }
  function closeModal5() {
    setIsOpen5(false);
  }

  let [isOpen6, setIsOpen6] = useState(false);

  function openModal6() {
    setIsOpen6(true);
  }
  function closeModal6() {
    setIsOpen6(false);
  }

  const cancelButtonRef = useRef(null);

  //End of Cool Down Time
  const endOfCoolDown = endTime + duration;

  //Current Time
  const currentTime = Date.now() / 1000;

  const noopProposalTxn = async (sender, index, txnType, assets, accounts) => {
    // const computedPass2 = proposalWonVotes(uv, dv);
    // const computedPass1 = proposalPassedThreshold(
    //   uv,
    //   dv,
    //   threshold,
    //   totalStake
    // );
    // let passed;
    // let pass1;
    // let pass2;
    // if (!computedPass1 && computedPass2) {
    //   pass1 = "right";
    // } else if (computedPass1 && !computedPass2) {
    //   pass2 = "right";
    // } else if (computedPass1 === true && computedPass2) {
    //   passed = "right";
    // }

    // //console.log(passed);

    try {
      const appArgs = [];
      appArgs.push(
        new Uint8Array(Buffer.from(txnType)) //noop
      );
      let params = await client.getTransactionParams().do();
      params.fee = 1000;
      params.flatFee = true;
      // create unsigned noop transaction
      let nptxn = algosdk.makeApplicationNoOpTxn(
        sender,
        params,
        index,
        appArgs,
        accounts,
        undefined,
        assets
      );

      // Sign the transactions together using the wallet

      const encodedTransaction1 = algosdk.encodeUnsignedTransaction(nptxn);

      const signedTransactions = await signTransactions([encodedTransaction1]);

      let tx = await client.sendRawTransaction(signedTransactions).do();
      //console.log("Noop + Asset Transfer Transaction : " + tx.txId);

      //console.log("Proposal is set to be paid");

      // Wait for transaction to be confirmed
      const confirmedTxn = await algosdk.waitForConfirmation(
        client,
        tx.txId,
        4
      );

      // if (isVotesPassed && isThresholdPassed) {
      //   setIsExploding(true);
      // } else {
      //   setIsExploding(false);
      // }

      //console.log("confirmed" + confirmedTxn);

      //Get the completed Transaction
      // console.log(
      //   "Transaction " +
      //     tx.txId +
      //     " confirmed in round " +
      //     confirmedTxn["confirmed-round"]
      // );

      const computedPass2 = await proposalWonVotes(uv, dv);
      const computedPass1 = await proposalPassedThreshold(
        uv,
        dv,
        threshold,
        totalStake
      );

      // Set the values in state
      // setPass1(computedPass1);
      // setPass2(computedPass2);

      setIsExploding(true);

      // let voteStatus;
      // if ((passed = "right")) {
      //   voteStatus = "Successful";
      //   setIsExploding(true);
      // } else {
      //   voteStatus = "Unsuccessful";
      // }
      const date = Date.now();
      const voteStatus = "Successful";
      const newProposal = {
        date,
        down: dv,
        endTime,
        description: p,
        proposalType: proposal_Type,
        status: voteStatus,
        txns: tx.txId,
        up: uv,
        receiverAmount: pv,
      };

      try {
        const addedProposalRef = await addProposal(newProposal);
        //console.log("Proposal added with ID: ", addedProposalRef.id);
        // Reset form or navigate to another page
      } catch (error) {
        //console.error("Error adding proposal: ", error);
      }

      setTxnsId(tx.txId);
      setLoading(false);
      openModal2(true);
    } catch (err) {
      // console.log("============Got here 2222===========");

      console.log(err);
      if (
        err.message.includes("Transaction Request Rejected") ||
        err.message.includes("Confirmation Failed")
      ) {
        setLoading(false);
        openModal5(true);
      } else {
        setLoading(false);
        // console.log("============Got here 1111===========");
        setWarning("Network Error. Please Try Again!");
        openModal4(true);
      }
    }
  };

  const assestOptin = async (sender, index) => {
    // closeModal1(false);

    if (!account) {
      setLoading(false);
      toast.warn("Please connect wallet.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
      });
    }
    setLoading(true);
    setProcess("Opting in to Fame Membership...");
    try {
      let params = await client.getTransactionParams().do();
      params.fee = 1000;
      params.flatFee = true;

      let tnx = algosdk.makeAssetTransferTxnWithSuggestedParams(
        sender,
        sender,
        undefined,
        undefined,
        0,
        undefined,
        index,
        params
      );

      let txns = [tnx];
      txns = algosdk.assignGroupID(txns);

      // Sign the transactions together using the wallet
      const encodedTransaction1 = algosdk.encodeUnsignedTransaction(tnx);

      const signedTransactions = await signTransactions([encodedTransaction1]);
      let tx = await client.sendRawTransaction(signedTransactions).do();
      //console.log("Noop + Asset Transfer Transaction: " + tx.txId);

      const confirmedTxn = await algosdk.waitForConfirmation(client, tx.txId);
    } catch (err) {
      console.log(err);
      if (err.message.includes("Transaction Request Rejected")) {
        setLoading(false);

        toast.error("Transaction Cancelled!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      } else {
        setLoading(false);
        // console.log("============Got here 333333===========");

        toast.error("Network Error! Please try again.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      }
    }
  };

  const claim = async () => {
    setLoading(true);
    if (!account) {
      setWarning("Please Connect Wallet!");
      setLoading(false);
      openModal4(true);
    } else if (currentTime > endOfCoolDown) {
      setWarning("Time for Claiming has Passed. Please propose again.");
      setLoading(false);
      openModal4(true);
    } else {
      const accountInfo = await client.accountInformation(account).do();

      const optedInAsset = accountInfo["assets"].some(
        (asset) => asset["asset-id"] === proposedToken
      );

      if (optedInAsset) {
        //console.log("User has opted into Asset.");

        await noopProposalTxn(account, app_id, "x", [proposedToken], [account]);
      } else {
        // console.log("==================Got Here==================");
        await assestOptin(account, proposedToken);
        await noopProposalTxn(account, app_id, "x", [proposedToken], [account]);
        setLoading(false);
      }
    }
  };

  function setOn() {
    setIsExploding(true);
  }

  function setOff() {
    setIsExploding(false);
  }

  const confettiColors = [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4CAF50",
    "#8BC34A",
    "#CDDC39",
    "#FFEB3B",
    "#FFC107",
    "#FF9800",
    "#FF5722",
    "#795548",
  ];

  return (
    <>
      {isExploding && (
        <Confetti
          width={width}
          height={height2}
          colors={confettiColors}
          numberOfPieces={2000}
          gravity={0.2}
          style={{ zIndex: "10000" }}
          recycle={true}
        />
      )}
      {loading && (
        <div className=" top-0 left-0 w-full h-full z-50 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity opacity-100">
          <div
            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
            style={{
              backgroundColor: "white",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "2",
              height: "180px",
              width: "300px",
              justifyContent: "center",
            }}
          >
            <img src={Loading} alt="loading" className="flex ml-20" />
            <div>
              <p className="text-center text-sm">{process}</p>
            </div>
          </div>
        </div>
      )}

      <button
        onClick={claim}
        style={{ margin: "0 auto", display: type }}
        className="flex i-fame uppercase block transition ease-in-out mb-40 delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-4 py-8 text-base font-semibold leading-7 text-white bg-green ring-1 ring-gray-900/10 hover:ring-gray-900/20"
      >
        {proposal_Type === "re"
          ? "CONFIRM REFERENDUM AND CLAIM 0.1 FAME"
          : "CLAIM PROPOSED TOKEN"}
      </button>

      {/* Claim Success */}
      <Transition show={isOpen2} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeModal2}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="text-center">
                      <BsFillCheckCircleFill className="text-green donate-check inline-block" />
                      <p className="mt-3 donate-confirmed">
                        {proposal_Type === "re"
                          ? "Successfully Confirmed"
                          : proposedToken === fame
                          ? "Membership Card Successfully claimed"
                          : " Successfully Claimed Token!"}
                      </p>
                      <div className="flex mt-5">
                        <p
                          className="mt-1"
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Transaction Id :{" "}
                          <span
                            onClick={openModal6}
                            className="text-green cursor-pointer"
                          >
                            {txnsId}
                          </span>
                        </p>
                        <CopyToClipboard text={txnsId}>
                          <button className="inline-flex ml-3 item-center transition ease-in-out hover:text-green text-white hover:bg-white hover:outline delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-2 py-1 text-base font-semibold leading-7 text-white bg-green ring-1 ring-gray-900/10 hover:ring-gray-900/2">
                            <p>Copy</p>
                            <HiOutlineClipboardDocument
                              style={{ fontSize: "20px" }}
                              className="ml-2 mt-1  text-center"
                            />
                          </button>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Warning */}
      <Transition show={isOpen4} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeModal4}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="text-center">
                      <TiWarning className="text-yellow-500 donate-check inline-block" />
                      <p className="mt-3 donate-confirmed">{warning}</p>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Transaction cancelled */}
      <Transition show={isOpen5} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeModal5}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="text-center">
                      <ImCancelCircle
                        className="text-brown
 donate-check inline-block"
                      />
                      <p className="mt-3 donate-confirmed">
                        Transaction Cancelled
                      </p>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* iframe */}
      <Transition show={isOpen6} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeModal6}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 ">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="text-center ">
                      <iframe
                        src={`https://allo.info/tx/${txnsId}`}
                        width={350}
                        height={500}
                      ></iframe>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
