import { db } from "../../firebase-config";

import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  orderBy,
} from "firebase/firestore";

const descriptionColRef = collection(db, "description");

// console.log(descriptionColRef);
export function addDescription(newDescription) {
  return addDoc(descriptionColRef, newDescription);
}

export function updateDescription(id, updatedDescription) {
  const descriptionDoc = doc(db, "description ", id);
  return updateDoc(descriptionDoc, updatedDescription);
}

export function deleteDescription(id) {
  const descriptionDoc = doc(db, "description ", id);
  return deleteDoc(descriptionDoc);
}

export function getAllDescriptions() {
  const q = query(descriptionColRef, orderBy("date", "desc"));
  const qs = query(descriptionColRef);
  // console.log(q);
  return getDocs(q);
}

export function getDescription(id) {
  const descriptionDoc = doc(db, "description", id);
  return getDoc(descriptionDoc);
}
