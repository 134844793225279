export const algosdk = require("algosdk");
export let client = new algosdk.Algodv2(
  "",
  "https://mainnet-api.algonode.cloud",
  ""
);

export let app_id = 1864245260;
export let fame = 1561981327;

export let fame_global = 142838028; // Mainnet
export let usdc = 31566704;

// test
// export let fame = 1913245399;
// export let app_id = 1913388832;

export const creatorAccount = JSON.parse(localStorage.getItem("walletconnect"))
  ?.accounts[0];
export let duration = 604800;
