import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../Assets/Img/logo.svg";
import Icon1 from "../Assets/Img/icon2.svg";
import Icon2 from "../Assets/Img/icon2.svg";
import Icon3 from "../Assets/Img/icon3.svg";
import Icon4 from "../Assets/Img/icon4.svg";
import Equito from "../Assets/Img/equito.svg";
import Support from "../Assets/Img/support.svg";
import Twitter from "../Assets/Img/twitter.svg";
import Discord from "../Assets/Img/discord.svg";
import Telegram from "../Assets/Img/gram.svg";

const Sidebar = () => {
  return (
    <div className="relative">
      <div className="sidebar">
        <NavLink
          exact
          to="/"
        >
          <img src={Logo} className="side-logo" alt="" />
        </NavLink>
        <nav className="navigation mt-10">
          <ul>
            <li>
              <NavLink
                exact
                to="/dashboard"
                activeClassName="active"
                className="btn-active"
              >
                <button className="inline-flex item-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-4 py-2 text-base leading-7 text-white font-semibold side-btn ">
                  <img src={Icon1} alt="" className="btn-image" />
                  DASHBOARD
                </button>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/bridge"
                activeClassName="active"
                className="btn-active"
              >
                <button className="inline-flex item-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-4 py-2 text-base leading-7 text-white font-semibold side-btn">
                  <img src={Icon2} alt="" className="btn-image" />
                  BRIDGE
                </button>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/analytics"
                activeClassName="active"
                className="btn-active"
              >
                <button className="inline-flex item-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-4 py-2 text-base leading-7 text-white font-semibold side-btn">
                  <img src={Icon4} alt="" className="btn-image" />
                  ANALYTICS
                </button>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dashboard"
                activeClassName="active"
                className="btn-active"
              >
                <button className="inline-flex item-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-4 py-2 text-base leading-7 text-white font-semibold side-btn">
                  <img src={Icon3} alt="" className="btn-image" />
                  DOCUMENTS
                </button>
              </NavLink>
            </li>
          </ul>
        </nav>

        <div className="mt-10 inline-flex power-sec">
          <p className="power text-white">Powered by :</p>
          <img src={Equito} className="ml-3" alt="" />
        </div>
        <div className="side-down">
          <button className="inline-flex item-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-4 py-2 text-base leading-7 text-white font-semibold support">
            <img src={Support} alt="" className="btn-image" />
            SUPPORT
          </button>

          <div className="border mt-5"></div>
          <div className="inline-flex mt-5 social-contain">
            <img src={Twitter} className="side-social" alt=" " />
            <img src={Discord} className="side-social" alt=" " />
            <img src={Telegram} className="side-social" alt=" " />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
