import { FadeIn } from "react-slide-fade-in";
import iconDao from "../Assets/Img/icon-dao.png";

export const Features = ({ image, title, text, direction }) => {
  return (
    <FadeIn
      from={direction}
      positionOffset={200}
      triggerOffset={200}
      delayInMilliseconds={0}
    >
      <div>
        <div className="grid grid-cols-3 gap-10 p-10 pb-0 lg:p-20 md:p-20 lg:pb-0 md:pb-0">
          <div className="col-span-1 ">
            <img src={image} alt="" className=" " />
          </div>
          <div className="col-span-2 flex lg:block md:block">
            <p
              className="mb-5 lg:mt-0 md:mt-0"
              style={{
                fontSize: "23px",
                fontWeight: "600",
                lineHeight: "28px",
                letterSpacing: "0.574999988079071px",
                textAlign: "left",
                margin: "auto 0",
              }}
            >
              {title}
            </p>
            <p
              className="lg:block md:block hidden mt-5"
              style={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "27px",
                letterSpacing: "0.38749998807907104px",
                textAlign: "left",
              }}
            >
              {text}
            </p>
          </div>
        </div>
        <p
          className="lg:hidden md:hidden block p-10 mt-5 pt-0"
          style={{
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "27px",
            letterSpacing: "0.38749998807907104px",
            textAlign: "left",
          }}
        >
          {text}
        </p>
      </div>
    </FadeIn>
  );
};
