import React, { useState } from "react";
import { useWallet } from "@txnlab/use-wallet";

export default function Connect() {
  const { providers, activeAccount } = useWallet();
  const [val, setVal] = useState();
  // Map through the providers.
  // Render account information and "connect", "set active", and "disconnect" buttons.
  // Finally, map through the `accounts` property to render a dropdown for each connected account.
  const { refresh, setRefresh } = useState();
  return (
    <div>
      {providers?.map(
        (provider, index) =>
          // index === 0 || index === 5
          index === 0 && (
            <div
              key={"provider-" + provider.metadata.id}
              style={{ margin: "auto", maxWidth: "700px" }}
              className="provider-container"
            >
              <div className="mb-3">
                <h4 className="inline-flex">
                  <img
                    width={30}
                    height={30}
                    style={{ borderRadius: "20px" }}
                    alt=""
                    className="mr-3"
                    src={provider.metadata.icon}
                  />
                  {provider.metadata.name} {provider.isActive && "[active]"}
                </h4>
              </div>
              <div className="inline-flex provider-buttons-fl">
                <button
                  className="mr-3 ml-3"
                  onClick={provider.connect}
                  disabled={provider.isConnected}
                >
                  Connect
                </button>
                <button
                  className="mr-3 ml-3"
                  onClick={provider.disconnect}
                  disabled={!provider.isConnected}
                >
                  Disconnect
                </button>
                {/* <button
                    className="mr-3 ml-3"
                    onClick={provider.setActiveProvider}
                    disabled={!provider.isConnected || provider.isActive}
                  >
                    Set Active
                  </button> */}
              </div>
              <div className="provider-select-fl items-center transition ease-in-out inline-flex rounded-lg px-1.5 text-base  leading-7 shadow-sm lg:mt-0 mt-3">
                {provider.isActive && provider.accounts.length && (
                  <select
                    value={activeAccount?.address}
                    onChange={(e) => provider.setActiveAccount(e.target.value)}
                    className="shorten-address"
                  >
                    {provider.accounts.map((account) => (
                      <option
                        key={"account-" + account.address}
                        value={account.address}
                      >
                        {account.address}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
          )
      )}
    </div>
  );
}
