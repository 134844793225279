import React, { useState, Fragment, useRef } from "react";
import { FadeIn } from "react-slide-fade-in";
import {
  Dialog,
  Transition,
  TransitionChild,
  DialogPanel,
} from "@headlessui/react";
import { SocialIcon } from "react-social-icons";
import { NavLink } from "react-router-dom";
import AppleStore from "../Assets/Img/ios.svg";
import PlayStore from "../Assets/Img/play.svg";
import Phone from "../Assets/Img/phone2.svg";

export default function Error() {
  const cancelButtonRef = useRef(null);
  const [showModal, setShowModal] = useState(false);

  function openModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  return (
    <>
      <section className="bg-secondary p-10 lg:p-20 lg:pl-28 lg:pb-10 md:pb-10 md:p-20">
        <div style={{ maxWidth: "1400px", margin: "0 auto" }}>
          <div className="pt-20 lg:grid grid-cols-10 gap-0">
            <div className="col-span-5 text-left text-white pt-12 pb-20">
              <FadeIn
                from="right"
                positionOffset={400}
                triggerOffset={200}
                delayInMilliseconds={0}
              >
                <p className="small-text ">Hmmm sorry, you're…</p>
                <p className="big-text mt-2">
                  <span className="text-tertiary">F</span>acing{" "}
                  <span className="text-tertiary">A</span>{" "}
                  <span className="text-tertiary">M</span>inor{" "}
                  <span className="text-tertiary">E</span>
                  rror.
                </p>
                <p className="text-left small-text mt-8">
                  Yes, something has gone slighty wrong, but let’s get you back
                  on track. Visit the{" "}
                  <span className="underline underline-offset-[6px]">
                    <NavLink to="/">FAME homepage.</NavLink>
                  </span>
                </p>
                <div className="flex flex-wrap space-x-2 mt-16">
                  <SocialIcon
                    url="https://www.facebook.com/AlgorandFAME"
                    bgColor="#ffffff"
                    style={{
                      width: "30px",
                      height: "30px",
                      marginTop: "15px",
                    }}
                  />
                  <SocialIcon
                    url="https://twitter.com/algorandFAME"
                    bgColor="#ffffff"
                    style={{
                      width: "30px",
                      height: "30px",
                      marginTop: "15px",
                    }}
                  />
                  <SocialIcon
                    url="https://youtube.com/@AlgoFame"
                    bgColor="#ffffff"
                    style={{
                      width: "30px",
                      height: "30px",
                      marginTop: "15px",
                    }}
                  />
                  <SocialIcon
                    url="https://www.linkedin.com/company/algofame"
                    bgColor="#ffffff"
                    style={{
                      width: "30px",
                      height: "30px",
                      marginTop: "15px",
                    }}
                  />
                </div>
              </FadeIn>
            </div>
            <div className="col-span-5">
              <FadeIn
                from="left"
                positionOffset={400}
                triggerOffset={200}
                delayInMilliseconds={0}
              >
                <div className="app">
                  <div>
                    <p className="text-white small-text mb-5 text-left -mt-8 ml-2.5">
                      Try our <br />
                      FAME app
                    </p>
                    <a href="https://play.google.com/store/apps/details?id=com.fame.app">
                      <img
                        src={PlayStore}
                        className="appstore"
                        alt="Play Store"
                      />
                    </a>

                    <img
                      className="appstore ml-2.5"
                      src={AppleStore}
                      alt="Apple Store"
                      onClick={() => setShowModal(true)}
                    />
                  </div>
                  <img src={Phone} alt="App" />
                </div>
              </FadeIn>
            </div>
          </div>
        </div>
        <Transition show={showModal} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={closeModal}
          >
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
                <TransitionChild
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 ">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="text-center">
                        <p className="p-5 donate-confirmed">Coming Soon !</p>
                      </div>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </Transition>
      </section>
    </>
  );
}
