import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const NavLinks = () => {
  const [click, setClick] = useState(false);

  const closeMobileMenu = () => setClick(false);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <>
      <li className="nav-item sm-items">
        <NavLink
          to="/"
          className={({ isActive }) =>
            "nav-links" + (isActive ? " activated" : "")
          }
          onClick={closeMobileMenu}
        >
          home<span className="dot">.</span>
        </NavLink>
      </li>
      <li className="nav-item last sm-items">
        <NavLink
          to="/roadmap"
          className={({ isActive }) =>
            "nav-links" + (isActive ? " activated" : "")
          }
          onClick={closeMobileMenu}
        >
          roadmap<span className="dot">.</span>
        </NavLink>
      </li>
      <li className="nav-item sm-items">
        <NavLink
          to="/news"
          className={({ isActive }) =>
            "nav-links" + (isActive ? " activated" : "")
          }
          onClick={closeMobileMenu}
        >
          news<span className="dot">.</span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          to="/dao"
          className={({ isActive }) =>
            "nav-links" + (isActive ? " activated" : "")
          }
          onClick={closeMobileMenu}
        >
          DAO<span className="dot">.</span>
        </NavLink>
      </li>
      <li className="nav-item last sm-items relative inline-block text-left">
        <Menu>
          <div>
            <Menu.Button className="inline-flex w-full text-white text-sm ml-2.5 lg:ml-0">
              leagues <span className="dot">.</span>
              <ChevronDownIcon
                className=" h-5 w-5 text-black"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          <Menu.Items
            transition
            className="absolute left-0 z-10 mt-2 w-40 origin-top-right text-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-10 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
          >
            <div className="py-1">
              <Menu.Item>
                {({ focus }) => (
                  <NavLink
                    to="/leagues/fame"
                    className={classNames(
                      focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    Fame League
                  </NavLink>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ focus }) => (
                  <NavLink
                    to="/leagues/algorandfrance"
                    className={classNames(
                      focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    French League
                  </NavLink>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ focus }) => (
                  <NavLink
                    to="/leagues/enuguleague"
                    className={classNames(
                      focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    Enugu League
                  </NavLink>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Menu>
      </li>
    </>
  );
};

export default NavLinks;
