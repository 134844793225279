function proposalPassedThreshold(upvote, downvote, threshold, totalStake) {
  const totalVotes = upvote + downvote;

  // Check if totalStake is zero before performing the division
  if (totalStake === 0) {
    return false;
  }

  if (totalVotes > totalStake * (threshold / 100)) {
    return true;
  } else if (totalVotes < totalStake * (threshold / 100)) {
    return false;
  }
}

function proposalWonVotes(upvote, downvote) {
  // Check if totalStake is zero before performing the division
  if (upvote === downvote) {
    return false;
  }

  if (upvote > downvote) {
    return true;
  } else if (downvote > upvote) {
    return false;
  }
}

export { proposalPassedThreshold, proposalWonVotes };
