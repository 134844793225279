import React, { useState, useEffect, Fragment, useRef } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  Dialog,
  Transition,
  TransitionChild,
  DialogPanel,
  Menu,
} from "@headlessui/react";
import "./dao.css";
import { FadeIn } from "react-slide-fade-in";
// import Img1 from "../Assets/Img/logo.png";
import Forum from "../Components/Forum";
import Img4 from "../Assets/Img/image1.svg";
import Current from "../Components/Current";
import Submit from "../Components/Submit";
import Stake from "../Components/Stake";
import Unstake from "../Components/Unstake";
import Donate from "../Components/donate";
import { FaPowerOff } from "react-icons/fa";
import { useWallet, walletconnect } from "@txnlab/use-wallet";
import { getCountdownDate, getTimeUsed } from "../../Global/Countdown";
import { useTimers } from "../Controller/Constants";
import Claim from "../Components/Claim";
import NextProposal from "../Controller/NextProposal";
import { Previous } from "../Components/Previous";
import { powerByStakeDuration, powerByVote } from "../../Global/VotePower";
import { fame, app_id } from "../utils";
export default function Dao() {
  //Get User Wallet Address from Local Storage
  const [address, setAddress] = useState("CONNECT");
  const { activeAddress } = useWallet();
  const {
    walletBalance,
    algouBalance,
    stakedAmount,
    pv,
    p,
    uv,
    dv,
    days,
    hours,
    minutes,
    seconds,
    treasuryBalance,
    algoBalance,
    glFameBalance,
    usdcBalance,
    totalStake,
    lastVote,
    endTime,
    coolDown,
    duration,
    proposal_Type,
    proposer,
    birthday,
    numVote,
    userFameBalance,
    userUsdcBalance,
  } = useTimers();
  const account = activeAddress;

  // console.log(stakedAmount, walletBalance);

  let [isOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (!account) {
      setAddress("CONNECT");
    } else {
      setAddress(account);
    }
  }, [account, setAddress]);

  const cancelButtonRef = useRef(null);

  //GetCoundownDate
  const date = getCountdownDate(days, hours, minutes, seconds);

  const result = uv + dv;
  const upvote = ((uv / result) * 100).toFixed(2);

  //Treasury Fame Balance
  const realFameBal = (treasuryBalance - totalStake).toFixed(2);

  //theshold
  const threshold = ((30 / 100) * totalStake).toFixed(2);

  //Current Time
  const currentTime = Date.now() / 1000;

  //End of cool Time
  const endOfCoolDown = endTime + coolDown;

  //Voting Power
  function truncateDecimals(number, digits) {
    const multiplier = Math.pow(10, digits);
    return Math.floor(number * multiplier) / multiplier;
  }
  // console.log(stakedAmount);
  // console.log(numVote);
  // console.log(birthday);
  // console.log(currentTime);

  const power1 = powerByVote(stakedAmount, numVote);
  const power2 = powerByStakeDuration(
    stakedAmount,
    birthday,
    currentTime,
    power1
  );
  const number = Number(power2);
  const votingPower = truncateDecimals(number, 2);
  // console.log(votingPower);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <div>
        <section className="eboyin-dao-hero pt-20 p-10 lg:p-20 lg:pb-10 md:pb-10 md:p-20">
          <div style={{ maxWidth: "1400px", margin: "0 auto" }}>
            <div className="pt-20">
              <FadeIn
                from="right"
                positionOffset={400}
                triggerOffset={200}
                delayInMilliseconds={0}
              >
                <p className="text-white  lg:pb-0 sm:pb-0 lg:text-right text-left dao-hero-heading">
                  Enugu League
                </p>
              </FadeIn>
            </div>
            {/* <Menu
              as="div"
              className="relative justify-right lg:text-right text-right"
            >
              <div>
                <Menu.Button className="inline-flex  gap-x-1.5 text-white px-3 py-2 text-sm font-semibold  ">
                  GOVERNOR
                  <ChevronDownIcon
                    className="-mr-1 h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-30 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href=""
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm text-center"
                          )}
                        >
                          GOVERNOR
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="https://algofame.org/dao"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm text-center"
                          )}
                        >
                          MAINNET
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="https://testnet.algofame.org/"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm text-center"
                          )}
                        >
                          TESTNET
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu> */}

            <div className="lg:mt-0 mt-5">
              <FadeIn
                from="left"
                positionOffset={400}
                triggerOffset={200}
                delayInMilliseconds={0}
              >
                <div className="dao-card md:mt-8">
                  <div className="lg:p-5 p-5  lg:pl-10 card-content">
                    <div className="grid lg:grid-cols-4 grid-cols-2 gap-4">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="inline-flex item-center grid"
                        href="https://app.tinyman.org/#/swap?asset_in=0&asset_out=142838028"
                      >
                        <button className="dao-btn inline-flex item-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-4 py-2 text-base font-semibold leading-7 text-white bg-green ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                          <img src={Img4} alt="" className="dao-icon" />
                          <span className="ml-3 dao-name">BUY</span>
                        </button>
                      </a>
                      <Stake />
                      <Unstake />
                      <Donate />
                    </div>
                    <div className="dao-hero-text ml-5">
                      <p className="text-green font-extrabold">
                        {walletBalance > 0 || stakedAmount > 0
                          ? "MEMBER"
                          : "No Membership"}
                      </p>
                      <p className="text-brown">
                        {stakedAmount > 0 ? "Staked" : "No Stake"}
                      </p>
                      <p className="text-green">
                        FAME balance - {userFameBalance} FAME
                      </p>

                      <p className="text-brown">Voting Power - {votingPower}</p>

                      <p className="text-black">
                        {/* ALGO balance - {algouBalance} ALGO */}
                      </p>
                      {/* <p className="text-brown">
                        USDC balance - {userUsdcBalance} USDC
                      </p> */}
                      {/* <p>
                        Hover over this text to see a tooltip:
                        <span class="tooltip">
                          Hover me
                          <span class="tooltiptext">This is a tooltip</span>
                        </span>
                      </p> */}
                    </div>
                  </div>
                </div>
              </FadeIn>
            </div>
          </div>
        </section>

        <section className="p-10 lg:p-20 lg:pt-10 md:pt-10 md:p-20">
          <div
            className="lg:grid grid-cols-5 gap-20"
            style={{ maxWidth: "1400px", margin: "0 auto" }}
          >
            {/* Show remaining Time for Next Proposal after Endtime  */}
            {currentTime > endOfCoolDown ? (
              <>
                <Submit />
                <Current type="none" />
              </>
            ) : currentTime > endTime && currentTime < endOfCoolDown ? (
              <NextProposal />
            ) : currentTime < endOfCoolDown ? (
              <>
                <Submit type="none" />
                <Current />
              </>
            ) : (
              <Submit />
            )}

            <div className="col-span-2 lg:pt-0 pt-10">
              <div className="vote-sec">
                <div className="tape treasury text-white p-5 bg-green">
                  <p>Enugu League treasury</p>
                  <img src={Img4} alt="logo" />
                </div>
                <div className="text-black lg:p-5 p-5 lg:pb-5 treasury-bal">
                  <p>
                    Card balance: <span>{realFameBal}</span>
                  </p>
                  <p>
                    Algo balance: <span>{algoBalance} ALGO</span>
                  </p>
                  <p>
                    Fame balance: <span>{glFameBalance} FAME</span>
                  </p>
                  <p>
                    Usdc balance: <span>{usdcBalance} USDC</span>
                  </p>
                  <p>
                    Total voting power: <span>{totalStake}</span>
                  </p>
                  <p>
                    Threshold: <span>{threshold} </span>
                  </p>
                  {/* <p>
                    AN Other ASA: <span>367,200,579</span>
                  </p> */}
                </div>
                <div className="token lg:flex p-5 lg:p-5 lg:pt-0">
                  <p>DApp {app_id} </p>
                  <p className="lg:block hidden"> • </p>
                  <p> NFT CARD $ENUGU {fame}</p>
                </div>
              </div>
              {currentTime > endTime ? <Forum type="none" /> : <Forum />}

              <Previous />
            </div>
          </div>
        </section>
        <section className="p-10 lg:p-20 lg:pt-10 md:pt-10 md:p-20 about-sec">
          <div
            className="lg:grid grid-cols-11 gap-5"
            style={{ maxWidth: "1400px", margin: "0 auto" }}
          >
            <div className="col-span-6">
              <p className="about">Welcome to the Enugu League!</p>
              <p className="mt-3 about-text">
                League DAOs operate similarly to the Global DAO but with some
                key differences in voting power determination and specific rules
                tailored to their unique community needs. Here’s a summary of
                the rules and benefits for League DAOs.
              </p>

              {/* <button className="flex mt-5 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-4 py-1.5 text-base font-semibold leading-7 text-white bg-secondary ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                More about the FAME DAO
              </button>
              <button className="flex mt-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-4 py-1.5 text-base font-semibold leading-7 text-white bg-black ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                DAO Constitution and rules
              </button> */}
            </div>
            <p className="how text-green  mt-5 col-span-9">How it works</p>

            <div className="col-span-3 mt-3 sm:mt-0">
              <p className="font-bold text-left">A - Rules of the DAO</p>
              {/* <p className="about-text mt-2">
                Here’s how you can maximize your influence in the FAME DAO:
              </p> */}
              <p className="about-text mt-2">
                <span className="font-bold">
                  1. Pre-Proposal Requirements:{" "}
                </span>
                Staking Requirement: You must stake your NFT membership card
                before making any proposals.
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">2. Proposal Limits: </span>
              </p>
              <p className="about-text mt-1">
                <span className="font-bold">- Funding Request Cap: </span>You
                can request up to 10% of the available funds in $FAME, $ALGO, or
                $USDC.
              </p>
              <p className="about-text mt-1">
                <span className="font-bold">- Proposal Justification: </span>
                Clearly explain why your proposal should receive funding.
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">3. Proposal and Voting Costs:</span>
              </p>
              <p className="about-text mt-1">
                <span className="font-bold">- Proposal Fee: </span>It costs 2
                $ALGO to submit a proposal.
              </p>
              <p className="about-text mt-1">
                <span className="font-bold">- Voting Fee: </span>0.01 $ALGO.
              </p>
              <p className="about-text mt-1">
                <span className="font-bold">- New Member Reward: </span>Any new
                added member earns 1 $ALGO from the proposer.
              </p>
              <p className="about-text mt-1">
                <span className="font-bold">- Proposee Reward:</span>Any
                proposee who claims the funds after a successful vote gets 1
                $ALGO back.
              </p>
            </div>

            <div className="col-span-3 lg:mt-0 mt-5">
              <p className="font-bold text-left">
                {" "}
                B - Boost Your Voting Power
              </p>

              <p className="about-text mt-2">
                <span className="font-bold">1. NFT Membership Card:</span>
              </p>
              <p className="about-text mt-1">
                <span className="font-bold">- Base Voting Power: </span>To gain
                proposal and voting power, you need to stake your NFT membership
                card. Simply holding it in your wallet is not enough.
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">2. Increase Voting Power:</span>
              </p>
              <p className="about-text mt-1">
                <span className="font-bold">- Monthly Growth:</span>
                Stake your NFT membership card, and your voting power increases
                by 10% each month. Stay for 5 months, and you gain 50% more
                voting strength.
              </p>
              <p className="about-text mt-1">
                <span className="font-bold">- Voting Engagement: </span>
                Every time you vote, you receive a 10% boost in voting power, up
                to 50% after casting 5 votes. For example, staking your NFT for
                5 months and voting 5 times doubles your voting power.
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">3. Reset on Unstaking:</span>
              </p>
              <p className="about-text mt-1">
                <span className="font-bold">- Reset Condition: </span>Unstaking
                your NFT membership card resets all accumulated voting power
                bonuses.
              </p>
            </div>

            <div className="col-span-5 lg:mt-0 mt-5">
              <p className="font-bold text-left">
                C - Becoming a League Governor
              </p>
              <p className="about-text mt-2">
                To avoid the plutocracy problem often seen in DAOs, where
                governance is dominated by those who can afford to buy the most
                tokens, League DAOs implement a unique governance structure that
                decentralizes power organically:
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">1. Initial Governor:</span>
              </p>
              <p className="about-text mt-1">
                - Governance starts with one initial governor who proposes and
                votes to add new governors.
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">2. Adding Governors:</span>
              </p>
              <p className="about-text mt-1">
                <span className="font-bold">- Proposing New Governors: </span>
                The initial governor proposes new governors. Each new governor
                must be voted in by the current governors.
              </p>

              <p className="about-text mt-1">
                <span className="font-bold">- Voting Process: </span>
                Proposals to add new governors follow the same voting rules as
                other proposals, requiring more upvotes than downvotes and
                participation from at least 30% of the total voting power.
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">
                  3. Decentralization of Power:{" "}
                </span>
              </p>
              <p className="about-text mt-1">
                - As new governors are added, power becomes more decentralized,
                promoting balanced and fair decision-making.
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">4. Voting Power Dynamics:</span>
              </p>
              <p className="about-text mt-1">
                - With each new governor, voting power is distributed more
                evenly, fostering active engagement and preventing any single
                individual from holding too much influence.
              </p>
            </div>

            <div className="col-span-11 lg:mt-0 mt-5">
              <p className="font-bold text-left"> D - Timelines to Watch</p>

              <p className="about-text mt-2">
                <span className="font-bold">1. Voting Period:</span>
              </p>
              <p className="about-text mt-1">
                <span className="font-bold">- Duration:</span>Each live proposal
                has a 7-day voting period for the community to decide.
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">2. Token Claim Period:</span>
              </p>
              <p className="about-text mt-1">
                <span className="font-bold">- Claim Window:</span>
                If your proposal is approved, you have 24 hours to claim your
                tokens.
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
