export const algosdk = require("algosdk");

export let client = new algosdk.Algodv2(
  "",
  "https://mainnet-api.algonode.cloud",
  ""
);

// export let app_id = 225262568;
export let app_id = 1298107018;

export let fame = 142838028; // Mainnet

export const creatorAccount = JSON.parse(localStorage.getItem("walletconnect"))
  ?.accounts[0];
export let duration = 604800;
