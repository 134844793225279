import Claim from "../Components/Claim";
import React, { useEffect } from "react";
import { endOfCool } from "./Countdown";
import { useTimer } from "../Controller/Constants";
import { useWallet } from "@txnlab/use-wallet";
import { BiSad } from "react-icons/bi";
import { FiClock } from "react-icons/fi";
import { AiOutlineAreaChart } from "react-icons/ai";
import { RiArrowDownFill, RiArrowUpDownFill } from "react-icons/ri";
import { getTimeUsed, thresholds } from "../Controller/Countdown";
import Progress from "../Components/Progress";
import { proposalPassedThreshold, proposalWonVotes } from "./Passed";
import Duration from "../Components/Duration";
import { addProposal } from "../Lib/Propose";

export default function NextProposal() {
  const {
    endTime,
    coolDown,
    proposer,
    p,
    uv,
    dv,
    totalStake,
    duration,
    threshold,
    pv,
    proposal_Type,
  } = useTimer();
  const { activeAddress } = useWallet();
  //End of cool Time
  const endOfCoolDown = endTime + coolDown;
  //Current Time
  const currentTime = Date.now() / 1000;
  const account = activeAddress;
  // console.log(proposer, account);

  const nextProposal = endOfCool(endOfCoolDown);

  // GetCountDownDate
  const result = uv + dv;
  let up, down;
  if (!result) {
    up = 0;
    down = 0;
  } else {
    up = ((uv / result) * 100).toFixed(2);
    down = ((dv / result) * 100).toFixed(2);
  }

  const thresholdReach = thresholds(uv, dv, totalStake).toFixed(2);

  let thresholdPercent;
  if (thresholdReach >= 100) {
    thresholdPercent = "Passed";
  } else {
    thresholdPercent = thresholdReach + "%";
  }

  //GetCountDownDuration
  const voteDurationCount = getTimeUsed(endTime, duration);

  const canClaim =
    currentTime < endOfCoolDown &&
    currentTime > endTime &&
    account === proposer &&
    p !== "A";

  const computedPass2 = proposalWonVotes(uv, dv);
  const computedPass1 = proposalPassedThreshold(uv, dv, threshold, totalStake);

  const isSuccessful = computedPass1 && computedPass2;
  const didNotMeetThreshold = !computedPass1 && computedPass2;
  const didNotMeetVotes = computedPass1 && !computedPass2;
  const didNotMeetAny = !computedPass1 && !computedPass2;

  useEffect(() => {
    const hasFunctionRunBefore = localStorage.getItem("hasFunctionRun");
    if (
      canClaim &&
      hasFunctionRunBefore != "true" &&
      (didNotMeetThreshold || didNotMeetVotes || didNotMeetAny)
    ) {
      const date = Date.now();
      let reason;
      if (didNotMeetThreshold) {
        reason = "The proposal failed to meet the required threshold.";
      } else if (didNotMeetVotes) {
        reason = "The vote count did not reach the 50% of vote count.";
      } else if (didNotMeetAny) {
        reason =
          "The proposal did not meet the threshold, and the vote count was below 50%.";
      }
      const voteStatus = "Unsuccessful";

      const newProposal = {
        date,
        down: dv,
        endTime,
        description: p,
        proposalType: proposal_Type,
        status: voteStatus,
        txns: reason,
        up: uv,
        receiverAmount: pv,
      };

      async function addProposalToDatabase() {
        try {
          const addedProposalRef = await addProposal(newProposal);
          console.log("Proposal added with ID: ", addedProposalRef.id);
          // Reset form or navigate to another page
        } catch (error) {
          console.error("Error adding proposal: ", error);
        }
      }
      localStorage.setItem("hasFunctionRun", "true");

      // Call the function to add the proposal when the component mounts
      addProposalToDatabase();
    }
  }, [canClaim]); // Run the effect whenever canClaim changes

  return (
    <div className="mt-20 lg:text-2xl col-span-3">
      <p> Last voting Period has ended!</p>
      <p>The time remaining for opening of Next Proposal is :</p>
      <p className="text-primary lg:mt-10 mt-5 mb-10 uppercase lg:text-5xl text-2xl font-extrabold">
        {nextProposal}
      </p>

      {/* Show Claim Button to address that Proposed */}
      {canClaim && isSuccessful ? (
        <Claim />
      ) : canClaim && didNotMeetThreshold ? (
        <>
          <BiSad className="text-primary donate-check m-auto" />
          <p>
            Unfortunately, your proposal did not succeed because it did not meet
            the required threshold.
          </p>
        </>
      ) : canClaim && didNotMeetVotes ? (
        <>
          <BiSad className="text-primary donate-check  m-auto " />
          <p>
            Unfortunately, your proposal didn't win because the number of votes
            in favor was less than 50% of the total votes cast.
          </p>
        </>
      ) : canClaim && didNotMeetAny ? (
        <>
          <BiSad className="text-primary donate-check m-auto" />
          <p>
            Unfortunately, your proposal didn't win because the number of votes
            in favor was less than 50% of the total votes cast and it did not
            meet the required threshold.
          </p>
        </>
      ) : (
        <Claim type="none" />
      )}
      {p === "A" ? (
        "Proposer Claimed Token already"
      ) : (
        <div
          className="lg:col-span-3 col-span-3 lg:p-10 p-5 mt-5 inline-flex item-center "
          style={{ height: "250px" }}
        >
          <div className="lg:ml-5 ml-3">
            <Progress percentup={up} percentdn={down} bg="bg-secondary" />
            <RiArrowUpDownFill className="mt-2" />
          </div>
          <div className="lg:ml-5 md:ml-5 ml-3">
            <Duration percent={thresholdReach} bg="bg-primary" />
            <AiOutlineAreaChart className="mt-2" />
          </div>
          <div className="ml-10">
            <p>Threshold : {thresholdPercent}</p>
            <p className="mt-3">Up Votes : {uv}</p>
            <p className="mt-3">Down Votes : {dv}</p>
            <div className=" pl-5 lg:pl-10" style={{ fontSize: "15px" }}>
              <div className="grid lg:grid-cols-0 md:grid-cols-0 lg:flex md:flex">
                <div className="inline-flex col-span-1">
                  <RiArrowUpDownFill className="mt-2" />
                  <p className="ml-2"> : </p>
                </div>
                <div className="col-span-4 " style={{ alignItems: "" }}>
                  <div className="inline-flex">
                    <div className="bg-secondary float-left lg:ml-2 md:ml-2 h-3 w-3 mt-2.5"></div>
                    <p className="ml-2">- Up Votes</p>
                  </div>
                  <div className="inline-flex">
                    <div className="bg-black lg:ml-10 md:ml-10 ml-5 h-3 w-3 mt-2.5"></div>
                    <p className="ml-2">- Down Votes</p>
                  </div>
                </div>
              </div>
              <div className="inline-flex ml-5">
                <AiOutlineAreaChart className="mt-2" />
                <span className="ml-2"> - Threshold : {thresholdPercent}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
