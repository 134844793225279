import axios from "axios";
import md5 from "md5";
// import getForum from "../Lib/Forum";
import { getForum } from "../../Lib/LeaguesForum";

const getForumData = async (
  proposer,
  endTime,
  proposalTime,
  setResults,
  showToast
) => {
  try {
    const proposerCode = proposer?.substring(0, 7) || "";
    const proposal = proposerCode + endTime;
    // console.log(proposal);
    const data = await getForum(proposal);
    // console.log(data);
    const updatedForum = await Promise.all(
      data.map(async (forum) => {
        const userHash = md5(
          forum.user_id.substring(0, 4) + forum.user_id.slice(-6)
        ).substring(0, 7);

        try {
          const response = await axios.get(
            `https://api.nf.domains/nfd/lookup?address=${forum.user_id}`
          );
          if (response.status === 200) {
            const userName = response.data[forum.user_id]?.name;
            // console.log(userName);
            return {
              ...forum,
              time: proposalTime(forum.date),
              user: userName,
            };
          }
        } catch (error) {
          // console.error("Error fetching data:", error);
        }

        // If the request failed or the status code was not 200, use the MD5 hash
        return {
          ...forum,
          time: proposalTime(forum.date),
          user: userHash,
        };
      })
    );

    // Set the updated forum data
    setResults(updatedForum); // Use setResults from the component
  } catch (error) {
    console.error("Error retrieving forum data:", error);
  }
};

export default getForumData;
