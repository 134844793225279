import React from "react";
import File from "../Assets/FAME Deck.pdf";
// import Image from "../Assets/Img/f6s.png";
import "react-responsive-modal/styles.css";
export default function Button() {
  return (
    <>
      <a
        href={File} // URL to the file you want to download
        download="Fame_Pitch_Deck.pdf" // The name of the file to be downloaded
        className="inline-flex transition ease-in-out delay-150 text-[12px] hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-4 py-1.5 text-base font-semibold leading-7 text-primary bg-white ring-1 ring-gray-900/10 hover:ring-gray-900/20"
      >
        <span>DOWNLOAD FAME DECK</span>
      </a>
    </>
  );
}
