export const algosdk = require("algosdk");

export let client = new algosdk.Algodv2(
  "",
  "https://mainnet-api.algonode.cloud",
  ""
);

//Enugu League
export let app_id = 2139126558;
export let fame = 2137094543;

export let fame_global = 142838028;
export let usdc = 31566704;

//test on mainnet
// export let fame = 1913245399;
// export let app_id = 1913388832;

export const creatorAccount = JSON.parse(localStorage.getItem("walletconnect"))
  ?.accounts[0];
export let duration = 604800;

// //=============Test on testnnet =============//
// export let client = new algosdk.Algodv2(
//   "",
//   "https://testnet-api.algonode.cloud",
//   ""
// );
// export let fame = 628646526; //testnet
// export let app_id = 648737864;
// export let fame_global = 148814612;
// export let usdc = 10458941;

// export let duration = 300;
