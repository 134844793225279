import React from "react";
import Image from "../Assets/Img/app-store-button.png";
import "react-responsive-modal/styles.css";
export default function AppStore() {
  return (
    <>
      <a
        href="https://apps.apple.com/us/app/algofame/id6461848634"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={Image}
          className="inline-flex h-10 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg text-base font-semibold leading-7 text-primary  ring-1 ring-gray-900/10 hover:ring-gray-900/20"
        ></img>
      </a>
    </>
  );
}
