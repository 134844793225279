import React from "react";

const Progress = ({ percentup, percentdn, bg }) => {
  return (
    <>
      <div
        className="bg-grey relative"
        style={{
          height: "100%",
          maxHeight: "400px",
          width: "20px",
          borderRadius: "10px",
          margin: "0 auto",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          className={`bg-grey`}
          style={{
            height: `50%`,
            width: "20px",
            bottom: "0",
            transition: "width 3s ",
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          <div
            className={`bg-blue`}
            style={{
              height: `${percentup}%`,
              width: "20px",
              bottom: "0",
              transition: "width 3s ",
            }}
          ></div>
        </div>
        <div>
          <p className="bg-white" style={{ height: "2px" }}></p>
        </div>
        <div
          className={` bg-grey`}
          style={{
            height: `50%`,
            width: "20px",
            bottom: "0",
            transition: "width 3s ",
            display: "flex",
          }}
        >
          <div
            className={`bg-red`}
            style={{
              height: `${percentdn}%`,
              width: "20px",
              bottom: "0",
              transition: "width 3s ",
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default Progress;
