import React, { useState, Fragment } from "react";
import "./bridge.css";
import Sidebar from "../Components/Sidebar";
import Chart from "../Assets/Img/chart.svg"

const Analytics = () => {
  return (
    <>
      <div className="grid lg:grid-cols-12">
        <div className="col-span-3 lg:block hidden">
          <Sidebar />
        </div>
        <div className="col-span-9 lg:m-10 m-5 pt-5">
          <div className="grid grid-cols-2">
            <div>
              <p className="text-secondary text-left bridge-head">analytics</p>
              <p className="bridge-subhead">Subtitle to go here</p>
            </div>
          </div>
          <div className="grid lg:grid-cols-2 gap-5">
            <div className=" grid grid-cols-2 gap-3  mt-10">
              <div className="bg-secondary total-card lg:p-5 p-4 text-left">
                <p className="total-item lg:mr-10">Total Transaction Volume</p>
                <p className="text-white total-fig">$32.05k</p>
              </div>
              <div className="bg-secondary total-card lg:p-5 p-4 text-left">
                <p className="total-item lg:mr-10">
                  Total transactions volume (last 24h)
                </p>
                <p className="text-white total-fig">$2.14k</p>
              </div>
              <div className="bg-secondary total-card lg:p-5 p-4 text-left">
                <p className="total-item lg:mr-10">Total Value Locked</p>
                <p className="text-white total-fig">$195.05k</p>
              </div>
              <div className="bg-secondary total-card lg:p-5 p-4 text-left">
                <p className="total-item lg:mr-10">Total transactions count</p>
                <p className="text-white total-fig">322</p>
              </div>
              <div className="bg-secondary total-card lg:p-5 p-4 text-left">
                <p className="total-item lg:mr-10">Total transactions volume</p>
                <p className="text-white total-fig">$32.05k</p>
              </div>
              <div className="bg-secondary total-card lg:p-5 p-4 text-left">
                <p className="total-item lg:mr-10">Total transactions volume (last 24h)</p>
                <p className="text-white total-fig">$2.14k</p>
              </div>
            </div>
            <div>
              <img src={Chart} alt="" className="mt-10" />
            </div>
          </div>
          <div className="dash-table mt-10">
            <div className="top-green"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Analytics;
