import React, { useState, Fragment } from "react";
import "./bridge.css";
import Sidebar from "../Components/Sidebar";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import Arrow from "../Assets/Img/arror.svg";
import { FaCheckCircle, FaPowerOff } from "react-icons/fa";
import { BiImport } from "react-icons/bi";

const wallets = [
  {
    id: 1,
    name: "Binance",
    avatar:
      "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
  },
  {
    id: 2,
    name: "Algorand",
    avatar:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
];

const tokens = [
  {
    id: 1,
    name: "Binance",
    avatar:
      "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
  },
  {
    id: 2,
    name: "Algorand",
    avatar:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const Bridge = () => {
  const [wallet, setWallet] = useState(wallets[0]);
  const [token, setToken] = useState(wallets[0]);
  return (
    <>
      <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-0">
        <div className="col-span-3 lg:block md:block hidden">
          <Sidebar />
        </div>
        <div className="col-span-9 pt-5 lg:m-10 md:m-10  only-scroll">
          <div className="grid grid-cols-2 m-5">
            <div>
              <p className="text-secondary text-left bridge-head">bridge</p>
              <p className="bridge-subhead">Transfer and Exchange</p>
            </div>
            <div className="text-right">
              <button className="items-center transition bg-primary ease-in-out inline-flex text-white rounded-lg px-4 py-1.5 text-base font-semibold leading-7  shadow-sm">
                <FaPowerOff />
                <span className="ml-3">CONNECT</span>
              </button>
            </div>
          </div>

          <div className="dash-table mt-10">
            <div className="top-green hidden lg:block md:block"></div>
            <div className="from-card lg:p-10 p-5 lg:m-5 lg:mt-8 md:p-10 md:m-5 md:mt-8 grid lg:grid-cols-11 md:grid-cols-11 grid-cols-12 gap-3">
              <p className="lg:col-span-2 col-span-2 text-black text-semibold text-left">
                From
              </p>
              <div className="lg:col-span-3 col-span-5">
                <p className="network-head">Network</p>
                <Listbox value={wallet} onChange={setWallet}>
                  {({ open }) => (
                    <div className="relative    ">
                      <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                        <span className="flex items-center">
                          <img
                            src={wallet.avatar}
                            alt=""
                            className="h-5 w-5 flex-shrink-0 rounded-full"
                          />
                          <span className="ml-3 block truncate">
                            {wallet.name}
                          </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {wallets.map((wall) => (
                            <Listbox.Option
                              key={wall.id}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "bg-indigo-600 text-white"
                                    : "text-gray-900",
                                  "relative cursor-default select-none py-2 pl-3 pr-9"
                                )
                              }
                              value={wall}
                            >
                              {({ selected, active }) => (
                                <>
                                  <div className="flex items-center">
                                    <img
                                      src={wall.avatar}
                                      alt=""
                                      className="h-5 w-5 flex-shrink-0 rounded-full"
                                    />
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "ml-3 block truncate"
                                      )}
                                    >
                                      {wall.name}
                                    </span>
                                  </div>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? "text-white"
                                          : "text-indigo-600",
                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  )}
                </Listbox>
              </div>
              <div className="lg:col-span-3 col-span-5">
                <p className="network-head">Token</p>
                <Listbox value={token} onChange={setToken}>
                  {({ open }) => (
                    <div className="relative    ">
                      <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                        <span className="flex items-center">
                          <img
                            src={token.avatar}
                            alt=""
                            className="h-5 w-5 flex-shrink-0 rounded-full"
                          />
                          <span className="ml-3 block truncate">
                            {wallet.name}
                          </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {tokens.map((tok) => (
                            <Listbox.Option
                              key={tok.id}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "bg-indigo-600 text-white"
                                    : "text-gray-900",
                                  "relative cursor-default select-none py-2 pl-3 pr-9"
                                )
                              }
                              value={tok}
                            >
                              {({ selected, active }) => (
                                <>
                                  <div className="flex items-center">
                                    <img
                                      src={tok.avatar}
                                      alt=""
                                      className="h-5 w-5 flex-shrink-0 rounded-full"
                                    />
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "ml-3 block truncate"
                                      )}
                                    >
                                      {tok.name}
                                    </span>
                                  </div>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? "text-white"
                                          : "text-indigo-600",
                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  )}
                </Listbox>
              </div>
              <div className="col-span-3 hidden lg:block"></div>
              <p className="col-span-2 text-black text-semibold text-left">
                Balance
              </p>
              <input
                className=" col-span-3 bg-white text-black text-right border rounded-md py-3 px-4 mb-1 w-full leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                // onChange={(e) => setRecipient(e.target.value)}
                placeholder="330.00"
              />
              <p className="underline text-secondary">Max</p>
            </div>
            <div className="to-card lg:p-10 md:p-10 p-5 lg:m-5 md:m-5 lg:mt-5 md:mt-5 mt-3  grid lg:grid-cols-11 md:grid-cols-11 grid-cols-12 gap-3 relative">
              <p className="lg:col-span-2 col-span-2 text-black text-semibold text-left">
                To
              </p>
              <div className="lg:col-span-3 col-span-5">
                <p className="network-head">Network</p>
                <Listbox value={wallet} onChange={setWallet}>
                  {({ open }) => (
                    <div className="relative    ">
                      <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                        <span className="flex items-center">
                          <img
                            src={wallet.avatar}
                            alt=""
                            className="h-5 w-5 flex-shrink-0 rounded-full"
                          />
                          <span className="ml-3 block truncate">
                            {wallet.name}
                          </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {wallets.map((wall) => (
                            <Listbox.Option
                              key={wall.id}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "bg-indigo-600 text-white"
                                    : "text-gray-900",
                                  "relative cursor-default select-none py-2 pl-3 pr-9"
                                )
                              }
                              value={wall}
                            >
                              {({ selected, active }) => (
                                <>
                                  <div className="flex items-center">
                                    <img
                                      src={wall.avatar}
                                      alt=""
                                      className="h-5 w-5 flex-shrink-0 rounded-full"
                                    />
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "ml-3 block truncate"
                                      )}
                                    >
                                      {wall.name}
                                    </span>
                                  </div>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? "text-white"
                                          : "text-indigo-600",
                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  )}
                </Listbox>
              </div>
              <div className="lg:col-span-3 col-span-5">
                <p className="network-head">Token</p>
                <Listbox value={token} onChange={setToken}>
                  {({ open }) => (
                    <div className="relative    ">
                      <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                        <span className="flex items-center">
                          <img
                            src={token.avatar}
                            alt=""
                            className="h-5 w-5 flex-shrink-0 rounded-full"
                          />
                          <span className="ml-3 block truncate">
                            {wallet.name}
                          </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {tokens.map((tok) => (
                            <Listbox.Option
                              key={tok.id}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "bg-indigo-600 text-white"
                                    : "text-gray-900",
                                  "relative cursor-default select-none py-2 pl-3 pr-9"
                                )
                              }
                              value={tok}
                            >
                              {({ selected, active }) => (
                                <>
                                  <div className="flex items-center">
                                    <img
                                      src={tok.avatar}
                                      alt=""
                                      className="h-5 w-5 flex-shrink-0 rounded-full"
                                    />
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "ml-3 block truncate"
                                      )}
                                    >
                                      {tok.name}
                                    </span>
                                  </div>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? "text-white"
                                          : "text-indigo-600",
                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  )}
                </Listbox>
              </div>
              <div className="lg:block hidden col-span-3"></div>
              <p className="col-span-2 text-black text-semibold text-left">
                Destination
              </p>
              <input
                className=" col-span-6 bg-white text-black text-right border rounded-md py-3 px-4 mb-1 w-full leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                // onChange={(e) => setRecipient(e.target.value)}
              />
              <div className="col-span-3"></div>
              <p className="col-span-2 text-black text-semibold text-left">
                Receive
              </p>
              <input
                className=" col-span-6 bg-white text-black text-right border rounded-md py-3 px-4 mb-1 w-full leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                // onChange={(e) => setRecipient(e.target.value)}
              />
              <div className="arrow-cover">
                <div className="arrow-back">
                  <img src={Arrow} className="arrow" alt="" />
                </div>
              </div>
            </div>
            <div className="grid lg:grid-cols-10 lg:p-10 md:grid-cols-10 md:p-10 p-5 transaction">
              <div className="inline-flex txns-container col-span-4 ">
                <p className="txns ">Transaction fee: </p>
                <p className="txns-fee ml-3">0.01</p>
              </div>
              <div className="col-span-6 bridge-btns">
                <button className="flex uppercase bridge-btn transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-4 py-1.5 text-base font-semibold leading-7 text-white bg-secondary ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                  <span>
                    <BiImport className=" mr-3 text-white inline-block text-bold" />
                  </span>
                  import asset
                </button>
                <button className="lg:block md:block hidden flex ml-5 uppercase bridge-btn block transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-4 py-1.5 text-base font-semibold leading-7 text-white bg-primary ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                  <span>
                    <FaCheckCircle className=" mr-3 text-white inline-block" />
                  </span>
                  CONFIRM TRANSACTION
                </button>
                <button className="lg:hidden md:hidden flex ml-5 uppercase bridge-btn block transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-4 py-1.5 text-base font-semibold leading-7 text-white bg-primary ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                  <span>
                    <FaCheckCircle className=" mr-3 text-white inline-block" />
                  </span>
                  CONFIRM
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Bridge;
