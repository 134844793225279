import { db } from "../../firebase-config";

import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  orderBy,
} from "firebase/firestore";

const proposalDocRef = doc(db, "fl_proposals ", "proposals");
const proposalColRef = collection(proposalDocRef, "frn_proposals");

export function addProposal(newProposal) {
  return addDoc(proposalColRef, newProposal);
}

export function updateProposal(id, updatedProposal) {
  const proposalDoc = doc(db, "proposals", id);
  return updateDoc(proposalDoc, updatedProposal);
}

export function deleteProposal(id) {
  const proposalDoc = doc(db, "proposals", id);
  return deleteDoc(proposalDoc);
}

export function getAllProposals() {
  const q = query(proposalColRef, orderBy("date", "desc"));
  const qs = query(proposalColRef);
  // console.log(getDocs(q));
  return getDocs(q);
}

export function getProposal(id) {
  const proposalDoc = doc(db, "proposals", id);
  return getDoc(proposalDoc);
}
