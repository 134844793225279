import { db } from "../firebase-config";
/////
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  orderBy,
  where,
} from "firebase/firestore";

const forumColRef = collection(db, "forum");

// console.log(forumColRef);
export function addForum(newForum) {
  return addDoc(forumColRef, newForum);
}

export function updateForum(id, updatedForum) {
  const forumDoc = doc(db, "main_desc", id);
  return updateDoc(forumDoc, updatedForum);
}

export function deleteForum(id) {
  const forumDoc = doc(db, "main_desc", id);
  return deleteDoc(forumDoc);
}

export async function getAllForums() {
  const q = query(forumColRef, orderBy("date", "desc"));
  const querySnapshot = await getDocs(q);
  const documents = [];

  querySnapshot.forEach((doc) => {
    documents.push(doc.data());
  });
  // console.log(documents);
  return documents;
}

export async function getForum(proposal_id) {
  const q = query(
    forumColRef,
    where("proposal_id", "==", proposal_id),
    orderBy("date", "desc")
  );
  const querySnapshot = await getDocs(q);
  const documents = [];
  querySnapshot.forEach((doc) => {
    documents.push(doc.data());
  });

  return documents;
}
