import React from "react";

const Partners = ({ link, name, image }) => {
  return (
    <div className="">
      <a href={link} target="_blank" rel="noreferrer">
        <img
          src={image}
          alt=""
          className="image "
          style={{ width: "211px", height: "auto" }}
        />
        <p className="subtitle mt-3">{name}</p>
      </a>
    </div>
  );
};

export default Partners;
