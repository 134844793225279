import { db } from "../firebase-config";

import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  orderBy,
  where,
} from "firebase/firestore";

const descriptionColRef = collection(db, "main_desc");

// console.log(descriptionColRef);
export function addDescription(newDescription) {
  return addDoc(descriptionColRef, newDescription);
}

export function updateDescription(id, updatedDescription) {
  const descriptionDoc = doc(db, "main_desc", id);
  return updateDoc(descriptionDoc, updatedDescription);
}

export function deleteDescription(id) {
  const descriptionDoc = doc(db, "main_desc", id);
  return deleteDoc(descriptionDoc);
}

export async function getAllDescriptions() {
  const q = query(descriptionColRef, orderBy("date", "desc"));
  const querySnapshot = await getDocs(q);
  const documents = [];

  querySnapshot.forEach((doc) => {
    documents.push(doc.data());
  });
  // console.log(documents);
  return documents;
}

export async function getDescription(short_desc) {
  const q = query(descriptionColRef, where("short_desc", "==", short_desc));
  const querySnapshot = await getDocs(q);
  const documents = [];
  querySnapshot.forEach((doc) => {
    documents.push(doc.data());
  });

  return documents;
}
