import md5 from "md5";
import React, { useEffect, useState, useRef } from "react";
import { useWallet } from "@txnlab/use-wallet";
import { useTimers } from "../Controller/Constants";
import { toast } from "react-toastify";
import { addForum, getForum } from "../../Lib/LeaguesForum";
import { proposalTime } from "../../Global/Countdown";
import axios from "axios";
import { HiPaperAirplane } from "react-icons/hi2";
// import { auth, logInWithEmailAndPassword, db } from;
import { auth, logInWithEmailAndPassword, db } from "../../firebase-config";

const Forum = ({ type }) => {
  useEffect(() => {
    // Perform login only once when the component mounts
    const login = logInWithEmailAndPassword(
      process.env.REACT_APP_USERNAME,
      process.env.REACT_APP_PASSWORD
    );
  }, []);

  const { walletBalance, stakedAmount, endTime, proposer } = useTimers();

  const { activeAddress } = useWallet("");
  const [comment, setComment] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const submit = async (event) => {
    event.preventDefault();
    // console.log(stakedAmount);

    if (!activeAddress) {
      showToast("Please connect wallet");
      setLoading(false);
    } else if (!comment) {
      showToast("Comment cannot be empty");
      setLoading(false);
      // } else if (walletBalance < 1) {
      //   showToast("You don't have the membership card");
      //   setLoading(false);
      // } else if (stakedAmount < 1) {
      //   showToast("You have to stake to participate.");
      //   setLoading(false);
    } else {
      const date = Date.now();
      const proposerCode = proposer.substring(0, 7);
      const proposal = proposerCode + endTime;

      forum(proposal, date, activeAddress, comment);
      setComment("");
    }
  };

  const scrollContainerRef = useRef();

  // Function to scroll to the bottom
  const scrollToBottom = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    // Scroll to bottom when results change
    scrollToBottom();
  }, [results]);

  const showToast = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: "dark",
    });
  };

  const forum = async (proposal_id, date, user_addr, message) => {
    try {
      setLoading(true);
      const addedForumRef = await addForum({
        proposal_id,
        date,
        user_id: user_addr,
        message,
      });
      // console.log("Comment added with ID: ", addedForumRef.id);
      getForumData(); // Reload forum data after adding a new comment
    } catch (error) {
      // console.error("Error adding Comment: ", error);
    }
  };

  const getForumData = async () => {
    try {
      const proposerCode = proposer?.substring(0, 7) || "";
      const proposal = proposerCode + endTime;
      const data = await getForum(proposal);

      const updatedForum = await Promise.all(
        data.map(async (forum) => {
          const userHash = md5(
            forum.user_id.substring(0, 4) + forum.user_id.slice(-6)
          ).substring(0, 7);

          try {
            const response = await axios.get(
              `https://api.nf.domains/nfd/lookup?address=${forum.user_id}`
            );
            if (response.status === 200) {
              const userName = response.data[forum.user_id]?.name;
              // console.log(userName);
              return {
                ...forum,
                time: proposalTime(forum.date),
                user: userName,
              };
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }

          // If the request failed or the status code was not 200, use the MD5 hash
          return {
            ...forum,
            time: proposalTime(forum.date),
            user: userHash,
          };
        })
      );

      // Set the updated forum data
      setResults(updatedForum);
      setLoading(false);
    } catch (error) {
      // console.error("Error retrieving forum data:", error);
      // showToast("Error retrieving forum data");
    }
  };

  useEffect(() => {
    getForumData();
  }, [proposer, endTime]); // Dependencies added to run the effect when proposer or endTime changes

  return (
    <div className="mt-10" style={{ display: type }}>
      <p className="previous">Proposal Forum</p>

      <div className=" text-left relative">
        <div
          ref={scrollContainerRef}
          className="overflow-y-scroll forum-sec p-5 pb-2"
          style={{ maxHeight: "300px" }} // Set a max height if needed
        >
          {results.length > 0 && (
            <div className=" flex flex-col-reverse ">
              {results.map((forum, index) => (
                <div key={index} className="forum-inner p-2 mb-10">
                  <p className="text-xs font-bold  pt-1">
                    @{forum.user}{" "}
                    <span className="ml-1 mr-1 font-normal">||</span>
                    {forum.time}
                    {proposer === forum.user_id && (
                      <span className="bg-red ml-2 rounded-md proposer uppercase">
                        Proposer
                      </span>
                    )}
                  </p>
                  <p className="text-sm mt-1">{forum.message}</p>
                </div>
              ))}
            </div>
          )}
          {results.length === 0 && (
            <div className="mb-5 text-center">
              <p>No comment yet.</p>
              <p className="">Be the first to comment!</p>
            </div>
          )}
        </div>

        <div className="bottom-0 left-0 right-0 mt-5 ">
          <div className="grid grid-cols-10">
            <textarea
              className="bg-gray-100 text-gray-700 col-span-8 rounded-3xl py-3 px-4 mb-3 mr-3 focus:outline-none rows-5 resize-none"
              placeholder="Type here..."
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              rows="1"
            ></textarea>
            <div className="col-span-2 w-full h-12 flex items-center justify-center rounded-3xl">
              {loading ? (
                // Render loading button while loading
                <div className="col-span-2 bg-blue w-full h-12 flex cursor-pointer items-center justify-center rounded-3xl">
                  <div className="lds-hourglass"></div>
                </div>
              ) : (
                // Render regular button when not loading
                <div
                  onClick={submit}
                  className="col-span-2 bg-blue w-full h-12 flex cursor-pointer items-center justify-center rounded-3xl"
                >
                  <HiPaperAirplane className="text-white text-3xl" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forum;
